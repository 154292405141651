import "../landingPage.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Footer from "../layout/footer";
import ContactUsForm from "../ContactUsForm";
import { Link } from "react-router-dom";
import React from "react";
import { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import CallToActionSection from "./commonComponent/CallToActionSection";
const industries = [
  {
    id: 1,
    industry_name: "Corporate Sector",
    img: "assets/icons/industry/corporate.svg",
  },
  {
    id: 2,
    industry_name: "Healthcare",
    img: "assets/icons/industry/healthcare.svg",
  },
  {
    id: 3,
    industry_name: "Manufacturing",
    img: "assets/icons/industry/manufacturing.svg",
  },
  {
    id: 4,
    industry_name: "Retail Chain",
    img: "assets/icons/industry/retail.svg",
  },
  {
    id: 5,
    industry_name: "Service Industry",
    img: "assets/icons/industry/service.svg",
  },
  {
    id: 6,
    industry_name: "Logistics",
    img: "assets/icons/industry/logistics.svg",
  },
  {
    id: 7,
    industry_name: "Recruitment and Staffing",
    img: "assets/icons/industry/staffing.svg",
  },
  {
    id: 8,
    industry_name: "Construction",
    img: "assets/icons/industry/corporate.svg",
  },
  {
    id: 9,
    industry_name: "Hospitality and IT",
    img: "assets/icons/industry/hospitality_it.svg",
  },
];

const HomePage = ({
  scollToHome,
  scollToFeature,
  scollToPlan,
  scollToContactUs,
}) => {
  var settings = {
    dots: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4000,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 820,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 540,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const navigate = useNavigate();

  const structuredData = {
    "@context": "https://schema.org",
    "@type": "Product",
    name: "Time Tango - Efficient Employee Attendance & Activity Tracking System",
    image: "https://timetango.in/assets/img/hero/hero_combine.svg",
    description:
      "The all-in-one platform for effortless leave management, insightful activity tracking, and smooth attendance management.",
    brand: {
      "@type": "Brand",
      name: "Time Tango",
      logo: "https://timetango.in/assets/logo3.svg",
      sameAs: [
        "https://www.facebook.com/people/TimeTango/61550584126267/",
        "https://www.linkedin.com/showcase/99884387/",
        "https://www.instagram.com/timetango.in/",
      ],
    },
    offers: {
      "@type": "Offer",
      url: "https://timetango.in",
      priceCurrency: "INR",
      price: "30",
    },
  };

  return (
    <>
      <Helmet>
        <title>
          Time Tango - Efficient Employee Attendance & Activity Tracking System.
        </title>
        <meta
          name="description"
          content="Track employee attendance with Time Tango’s efficient and user-friendly time tracking & activity tracking software. Simplify HR management today with additional feature as Leave management system."
        />
        <meta
          property="og:title"
          content="Time Tango - Efficient Employee Attendance & Activity Tracking System"
        />
        <meta
          property="og:description"
          content="Track employee attendance with Time Tango’s efficient and user-friendly time tracking & activity tracking software. Simplify HR management today with additional feature as Leave management system."
        />
        <meta
          property="og:image"
          content="https://timetango.in/assets/logo3.svg"
        />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://timetango.in/" />
        <link rel="canonical" href="https://timetango.in/" />
        <meta
          property="og:site_name"
          content="Timetango"
        />
        <meta name="language" content="english" />
        <meta
          name="google-site-verification"
          content="HeqJ8arMIZIDpCmRH1zlrNu601VS7Wp92JoTy_9bARM"
        ></meta>
        <script type="application/ld+json">
          {JSON.stringify(structuredData)}
        </script>
      </Helmet>
      <div
        data-bs-spy="scroll"
        data-bs-target="#navbar-example2"
        data-bs-offset="0"
      >
        <section
          id="home"
          className="homePage d-flex align-items-center"
          ref={scollToHome}
        >
          <div className="container">
            <div className="row">
              <Slider {...settings}>
                <div className="row align-items-center d-flex mx-auto">
                  <div className="col-md-5 col-lg-5 col-xs-12 col-sm-12 w-sm-100 pt-2 pt-md-0">
                    <h1 className="bannerTagLine h1formain">
                      Multiple Problems one solution
                    </h1>
                    <p className="fs-18">
                      The all-in-one platform for effortless leave management,
                      insightful activity tracking, and smooth attendance
                      management
                    </p>
                  </div>
                  <div className="col-md-7 col-lg-7 col-xs-12 banner_img w-sm-100">
                    <img
                      src="assets/img/hero/Hero_Combine.png"
                      alt="Banner_timetango_Image"
                      className="d-block w-100"
                    />
                  </div>
                </div>
                <div className="row align-items-center d-flex mx-auto">
                  <div className="col-md-5 col-lg-5 col-xs-12 col-sm-12 w-sm-100  pt-2 pt-md-0">
                    <h1 className="bannerTagLine h1formain">
                      Attendance Management
                    </h1>
                    <p className="fs-18">
                      The versatile attendance management tool available for
                      both mobile and desktop devices.
                    </p>
                    {/*  */}
                  </div>
                  <div className="col-md-7 col-lg-7 col-xs-12 banner_img w-sm-100">
                    <img
                      src="assets/img/hero/Hero_Attendance_management.png"
                      alt="Attendance Management"
                      className="d-block w-100"
                    />
                  </div>
                </div>
                <div className="row align-items-center d-flex mx-auto">
                  <div className="col-md-5 col-lg-5 col-xs-12 col-sm-12 w-sm-100 pt-2 pt-md-0">
                    <h1 className="bannerTagLine h1formain">
                      Activity Tracking
                    </h1>
                    <p className="fs-18">
                      Bring transperancy at workplace with live activity
                      tracking.
                    </p>
                  </div>
                  <div className="col-md-7 col-lg-7 col-xs-12 banner_img w-sm-100">
                    <img
                      src="assets/img/hero/Hero_Activity_Tracking.png"
                      alt="Activity Tracking"
                      className="d-block w-100"
                    />
                  </div>
                </div>
                <div className="row align-items-center d-flex mx-auto">
                  <div className="col-md-5 col-lg-5 col-xs-12 col-sm-12 w-sm-100  pt-2 pt-md-0">
                    <h1 className="bannerTagLine h1formain">
                      Leave Management
                    </h1>
                    <p className="fs-18">
                      Effortless leave management made easy with TimeTango’s
                      integrated platform
                    </p>
                  </div>
                  <div className="col-md-7 col-lg-7 col-xs-12 banner_img w-sm-100">
                    <img
                      src="assets/img/hero/Hero_Leave_managment.png"
                      alt="Leave Management"
                      className="d-block w-100"
                    />
                  </div>
                </div>
              </Slider>
            </div>
          </div>
        </section>

        {/* <OfferPopup /> */}
        <section className="section-padding section_2 bg-white">
          <div className="container">
            <div className="row section_2_content paddingx">
              <div className="col-md-7 col-xs-12 col-sm-12 col-lg-7 w-sm-100 text-center img-wrap">
                <img
                  src="assets/icons/mobile.png"
                  alt="mobile attendance App"
                  className=""
                />
              </div>
              <div className="col-md-5 col-lg-5 col-sm-12 col-xs-12 w-sm-100 text-sm-center mt-5 mt-md-0">
                <div className="row text-justify">
                  <h2 className="fs-2 mb-3 text-justify">
                    Unlock the Power of Modern &nbsp;
                    {/* <br /> */}
                    <span>Attendance Tracking</span>
                  </h2>
                  <p className="text-left pb-5 pb-md-0 mb-3 mb-md-5">
                    Are you tired of the hassle and inefficiency of traditional
                    attendance systems? Say goodbye to outdated paper sign-ins
                    and time-consuming HR paperwork. Embrace the future of
                    office check-in and checkout with TimeTango, the
                    cutting-edge mobile app that revolutionizes the way you
                    manage employee attendance.
                  </p>
                  <div className="d-flex justify-content-center">
                    <a href="/company-register" className="btn_custom">
                      Get Started Today
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* features */}
        <section className="section-padding features_section">
          <div className="container px-md-0">
            <div className="row paddingx">
              <h4 className="text-center px-1">
                Why Choose <span> TimeTango?</span>
              </h4>
            </div>
            <div className="row features_wrapper">
              <div className="col-lg-4 col-md-6 col-sm-6">
                <div className="feature_item">
                  <div className="feature_img">
                    <img
                      src="assets/icons/seamless.svg"
                      alt="Seamlessly Convenient"
                    />
                  </div>
                  <h6>Seamlessly Convenient</h6>
                  <p className="fs-16 text-center">
                    With TimeTango, associates can check-in and check-out
                    effortlessly with a simple tap on their mobile device,
                    capturing a secure image for authentication.
                  </p>
                </div>
              </div>
              <div className="col-lg-4 col-sm-6 col-md-6 ">
                <div className="feature_item">
                  <div className="feature_img ">
                    <img
                      src="assets/icons/image_auth.svg"
                      alt="Advanced Image Authentication"
                    />
                  </div>
                  <h6>Advanced Image Authentication</h6>
                  <p className="fs-16  text-center">
                    TimeTango ensures reliable authentication with a quick image
                    capture, preventing timesheet manipulation or buddy
                    punching.
                  </p>
                </div>
              </div>
              <div className="col-lg-4 col-sm-6 col-md-6 ">
                <div className="feature_item">
                  <div className="feature_img ">
                    <img
                      src="assets/icons/paperless.svg"
                      alt="Paperless and Eco-Friendly"
                    />
                  </div>
                  <h6>Paperless and Eco-Friendly</h6>
                  <p className="fs-16  text-center">
                    By using TimeTango, we can reduce the use of paper stacks,
                    which is an eco-friendly and more sustainable approach for
                    the environment.
                  </p>
                </div>
              </div>

              <div className="col-lg-4 col-sm-6 col-md-6 ms-auto">
                <div className="feature_item">
                  <div className="feature_img">
                    <img
                      src="assets/icons/realtime.svg"
                      alt="Real-Time Insights"
                    />
                  </div>
                  <h6>Real-Time Insights</h6>
                  <p className="fs-16  text-center">
                    Admin receives real-time notifications for associates
                    working hours, check-in, and check-out activities. HR gains
                    real-time insights into associates presence, check-ins, and
                    early checkouts.
                  </p>
                </div>
              </div>
              <div className="col-lg-4 col-sm-6">
                <div className="feature_item">
                  <div className="feature_img">
                    <img
                      src="assets/icons/userfriendly.svg"
                      alt="UserFriendly"
                    />
                  </div>
                  <h6>User-Friendly Interface</h6>
                  <p className="fs-16  text-center">
                    TimeTango is designed to offer a seamless experience for
                    both associates and admins,requiring minimal training and
                    maximizing user efficiency from day one.
                  </p>
                </div>
              </div>
              <div className="col-lg-4 col-sm-6">
                <div className="feature_item">
                  <div className="feature_img">
                    <img
                      src="assets/icons/userfriendly.svg"
                      alt="User-Friendly Interface"
                    />
                  </div>
                  <h6>Detailed Compliance Reports</h6>
                  <p className="fs-16  text-center">
                    HR can access comprehensive compliance reports for any
                    associates on a daily or monthly basis.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* The TimeTango Difference */}
        <section className="component-padding bg-white">
          <div className="container d-flex flex-column gap-4">
            <div className=" text-center paddingx">
              <h2 className="fs-1  fs-sm-4">The TimeTango Difference</h2>
              <p className="fs-16">
                At TimeTango, we understand the significance of an efficient and
                accurate attendance system. Our mission is to simplify HR
                processes, empower employees, and help businesses thrive. Our
                feature-rich solution offers.
              </p>
            </div>
            <div
              className="row align-items-center mb-4 paddingx"
              style={{ cursor: "pointer" }}
              onClick={() => {
                navigate("/wfh-support");
              }}
            >
              <div className="col-md-7 col-lg-7 col-xs-12 col-sm-12">
                <h4 className="fs-2">WFH Support</h4>
                <p className="fs-16">
                  The organization tracks approved WFH employee devices,
                  ensuring consistent device use for work and check-in/check-out
                  and also tracks location of associates.
                </p>
              </div>
              <div className="col-md-5 col-lg-5 col-xs-12 col-sm-12 ">
                <img
                  src="assets/img/wfh.svg"
                  alt="wfh-support"
                  className="mx-auto w-100 h-100"
                />
              </div>
            </div>
            <div
              className="row align-items-center mb-4 mt-4 mt-md-0 paddingx"
              style={{ cursor: "pointer" }}
              onClick={() => {
                navigate("/real-time-insight");
              }}
            >
              <div className="col-md-7 col-lg-7 col-xs-12 col-sm-12 order-md-2">
                <h4 className="fs-2 ">Real-time Insight</h4>
                <p className="fs-16">
                  Admin receives real-time notifications for associates working
                  hours, check-in, and check-out activities.
                </p>
              </div>
              <div className="col-md-5 col-lg-5 col-xs-12 col-sm-12 order-md-1">
                <img
                  src="assets/img/real_time_insights.svg"
                  alt="Real-time Insight"
                  className="mx-auto w-100 h-100"
                />
              </div>
            </div>
            <div
              className="row align-items-center mb-4 mt-4 mt-md-0 paddingx"
              style={{ cursor: "pointer" }}
              onClick={() => {
                navigate("/activity-tracking");
              }}
            >
              <div className="col-md-7 col-lg-7 col-xs-12 col-sm-12">
                <h4 className=" fs-2"> Activity Tracking</h4>
                <p className="fs-16">
                  {" "}
                  New activity tracking function brings complete transperancy to
                  your workforce with live insight on idel time and app usage.
                </p>
              </div>
              <div className="col-md-5 col-lg-5 col-xs-12 col-sm-12">
                <img
                  src="assets/img/activity_tracking.svg"
                  alt="Activity Tracking"
                  className="mx-auto w-100 h-100"
                />
              </div>
            </div>
            <div
              className="row align-items-center mb-4 mt-4 mt-md-0 paddingx"
              style={{ cursor: "pointer" }}
              onClick={() => {
                navigate("/leave-mangement");
              }}
            >
              <div className="col-md-7 col-lg-7 col-xs-12 col-sm-12 order-md-2">
                <h4 className="fs-2 ">Leave Management</h4>
                <p className="fs-16">
                  Comprehencing leave management system coupled with leave
                  tracking based on attendance.
                </p>
              </div>
              <div className="col-md-5 col-lg-5 col-xs-12 col-sm-12 order-md-1">
                <img
                  src="assets/img/leave_management.svg"
                  alt="Leave Management"
                  className="mx-auto w-100 h-100"
                />
              </div>
            </div>
            <div
              className="row align-items-center mb-4 mt-4 mt-md-0 paddingx"
              style={{ cursor: "pointer" }}
              onClick={() => {
                navigate("/compliences-report");
              }}
            >
              <div className="col-md-7 col-lg-7 col-xs-12 col-sm-12">
                <h4 className=" fs-2"> Detailed Compliance Reports</h4>
                <p className="fs-16">
                  {" "}
                  HR can access comprehensive compliance reports for any
                  associates on a daily or monthly basis.
                </p>
              </div>
              <div className="col-md-5 col-lg-5 col-xs-12 col-sm-12">
                <img
                  src="assets/img/detailed_compliance.svg"
                  alt="Detailed Compliance Reports"
                  className="mx-auto w-100 h-100"
                />
              </div>
            </div>
            <div
              className="row align-items-center mb-4 mt-4 mt-md-0 paddingx"
              style={{ cursor: "pointer" }}
              onClick={() => {
                navigate("/visitor-manegement");
              }}
            >
              <div className="col-md-7 col-lg-7 col-xs-12 col-sm-12 order-md-2">
                <h4 className=" fs-2"> Visitors Management</h4>
                <p className="fs-16">
                  {" "}
                  The visitor facility is available for pre-approved and prompt
                  visitors, with complete documentation for security purposes.
                </p>
              </div>
              <div className="col-md-5 col-lg-5 col-xs-12 col-sm-12 order-md-1">
                <img
                  src="assets/img/visitor_management.svg"
                  alt="Visitors Management"
                  className="mx-auto w-100 h-100"
                />
              </div>
            </div>
          </div>
        </section>

        {/* industries catered secion */}
        <section className="industries text-center section-padding">
          <div className="container">
            <div className="row g-3 paddingx">
              <h4 className="desh2">Industries Covered</h4>
            </div>

            <div className="row g-3 justify-content-center mx-4 paddingx">
              {industries.map((industry) => (
                <div
                  className="col-md-3 col-sm-6 col-lg-3 col-xs-12 align-items-center card shadow p-3 border-0 card-custom"
                  key={industry.id}
                >
                  <div className="img_wrapper mb-2">
                    <img
                      src={industry.img}
                      alt={industry.industry_name}
                      className="img-fluid"
                    />
                  </div>

                  <p className="fw-semibold text-dark fs-6">
                    {industry.industry_name}
                  </p>
                </div>
              ))}
            </div>
          </div>
        </section>

        {/* testimonial  start*/}
        <section className="testimonial_section section-padding">
          <div className="container">
            <div className="row paddingx">
              <h4 className="text-center">Customer Stories</h4>
            </div>
            <div className="row justify-content-around g-4 paddingx">
              <div className="col-sm-12 col-md-6 col-lg-6 d-flex">
                <div className="card p-3 client_card w-100">
                  <div className="card-body d-flex flex-column ">
                    <p className="card-text">
                      <span className="quote-icn me-2">
                        <svg width="20" height="20" viewBox="0 0 24 24">
                          <path d="M13 14.725c0-5.141 3.892-10.519 10-11.725l.984 2.126c-2.215.835-4.163 3.742-4.38 5.746 2.491.392 4.396 2.547 4.396 5.149 0 3.182-2.584 4.979-5.199 4.979-3.015 0-5.801-2.305-5.801-6.275zm-13 0c0-5.141 3.892-10.519 10-11.725l.984 2.126c-2.215.835-4.163 3.742-4.38 5.746 2.491.392 4.396 2.547 4.396 5.149 0 3.182-2.584 4.979-5.199 4.979-3.015 0-5.801-2.305-5.801-6.275z"></path>
                        </svg>
                      </span>
                      &nbsp;I just wanted to share my experience with Timetango.
                      It's been a real help for us at HighLeap Education
                      Consultant. The way it fits into our work and its
                      easy-to-use interface have made things smoother for us.
                      It's a solid tool that has improved our efficiency. Thanks
                      to the Timetango team for creating something practical and
                      effective!
                    </p>
                    <div className="client-name">
                      <h6>
                        <b> ~ Shraddha B</b>
                      </h6>
                      <p className="client_org">
                        {" "}
                        Founder, HighLeap Education Consultant
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-12 col-md-6 col-lg-6 d-flex">
                <div className="card p-3 client_card w-100">
                  <div className="card-body d-flex flex-column">
                    <p className="card-text">
                      <span className="quote-icn me-2">
                        <svg width="20" height="20" viewBox="0 0 24 24">
                          <path d="M13 14.725c0-5.141 3.892-10.519 10-11.725l.984 2.126c-2.215.835-4.163 3.742-4.38 5.746 2.491.392 4.396 2.547 4.396 5.149 0 3.182-2.584 4.979-5.199 4.979-3.015 0-5.801-2.305-5.801-6.275zm-13 0c0-5.141 3.892-10.519 10-11.725l.984 2.126c-2.215.835-4.163 3.742-4.38 5.746 2.491.392 4.396 2.547 4.396 5.149 0 3.182-2.584 4.979-5.199 4.979-3.015 0-5.801-2.305-5.801-6.275z"></path>
                        </svg>
                      </span>
                      &nbsp;I've been using Timetango for a while now, and it
                      has significantly improved our time management at Balaji
                      Enterprises. The user-friendly interface and seamless
                      integration make it easy for our team to stay organized.
                      It's a practical tool that has positively impacted our
                      workflow. Thanks to the Timetango team for providing such
                      a helpful solution.
                    </p>
                    <div className="client-name">
                      <h6>
                        <b> ~ D Kishor</b>
                      </h6>
                      <p className="client_org">Balaji Enterprises</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-12 col-md-6 col-lg-6 d-flex">
                <div className="card p-3 client_card w-100">
                  <div className="card-body d-flex  flex-column">
                    <p className="card-text">
                      <span className="quote-icn me-2">
                        <svg width="20" height="20" viewBox="0 0 24 24">
                          <path d="M13 14.725c0-5.141 3.892-10.519 10-11.725l.984 2.126c-2.215.835-4.163 3.742-4.38 5.746 2.491.392 4.396 2.547 4.396 5.149 0 3.182-2.584 4.979-5.199 4.979-3.015 0-5.801-2.305-5.801-6.275zm-13 0c0-5.141 3.892-10.519 10-11.725l.984 2.126c-2.215.835-4.163 3.742-4.38 5.746 2.491.392 4.396 2.547 4.396 5.149 0 3.182-2.584 4.979-5.199 4.979-3.015 0-5.801-2.305-5.801-6.275z"></path>
                        </svg>
                      </span>
                      &nbsp; Timetango is robust, reliable, and super intuitive.
                      The team is customer-focused, responsive, and offers the
                      most cost-effective solution. Impressed and highly
                      recommended!
                    </p>
                    <div className="client-name">
                      <h6>
                        <b> ~ Arun Kumar</b>
                      </h6>
                      <p className="client_org">Techspark Solutions</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* testimonial end */}
        <section
          className="section-padding botom_section bg_secondary"
          ref={scollToContactUs}
        >
          <div className="bubble"></div>
          <div className="container">
            <div className="row paddingx">
              <div className="col-sm-12 col-md-8 col-lg-7 mx-auto col-xs-12 offset-md-2">
                <h4 className="text-dark">
                  <span>Experience the Future of </span> Office
                  Attendance&nbsp;Today!
                </h4>
                <p className="mt-3 text-dark">
                  Say hello to a more efficient, paperless, and secure
                  attendance tracking experience with TimeTango. Join hundreds
                  of businesses worldwide who have already streamlined their
                  workforce management and made HR a breeze.
                </p>
              </div>
              {/* <div className="get_touch_btn">
            <a href="#contact-us">Try TimeTango Now</a>
          </div> */}
              <div className="get_touch_btn">
                <Link to="/company-register" className="mix-a">
                  Try TimeTango Now
                </Link>
              </div>
            </div>
          </div>
        </section>
        {/* componnent 4 */}
        <CallToActionSection></CallToActionSection>
      </div>
    </>
  );
};

export default HomePage;
