import "./common.css";
import { useEffect } from "react";
import { Helmet } from "react-helmet";
import CallToActionSection from "./commonComponent/CallToActionSection";
const VisitorMangement = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const structuredData = {
    "@context": "https://schema.org",
    "@type": "Product",
    name: "Visitor Management System & Attendance Tracker | Timetango",
    image: "https://timetango.in/assets/img/hero/hero_combine.svg",
    description:
      "Streamline visitor check-ins and time tracking with Timetango. Advanced employee attendance tracking and digital visitor management app for your business.",
    brand: {
      "@type": "Brand",
      name: "Time Tango",
      logo: "https://timetango.in/assets/logo3.svg",
      sameAs: [
        "https://www.facebook.com/people/TimeTango/61550584126267/",
        "https://www.linkedin.com/showcase/99884387/",
        "https://www.instagram.com/timetango.in/",
      ],
    },
    offers: {
      "@type": "Offer",
      url: "https://timetango.in",
      priceCurrency: "INR",
      price: "30",
    },
  };
  return (
    <>
      <Helmet>
        <title>
        Visitor Management System & Attendance Tracker | Timetango
        </title>
        <meta
          name="description"
          content="Streamline visitor check-ins and time tracking with Timetango. Advanced employee attendance tracking and digital visitor management app for your business."
        />
        <meta
          property="og:title"
          content="Visitor Management System & Attendance Tracker | Timetango"
        />
        <meta
          property="og:description"
          content="Streamline visitor check-ins and time tracking with Timetango. Advanced employee attendance tracking and digital visitor management app for your business."
        />
        <meta
          property="og:image"
          content="https://timetango.in/assets/logo3.svg"
        />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://timetango.in/" />
        <link rel="canonical" href="https://timetango.in/" />
        <meta
          property="og:site_name"
          content="Timetango"
        />
        <meta name="language" content="english" />
        <meta
          name="google-site-verification"
          content="HeqJ8arMIZIDpCmRH1zlrNu601VS7Wp92JoTy_9bARM"
        ></meta>
        <script type="application/ld+json">
          {JSON.stringify(structuredData)}
        </script>
      </Helmet>
      {/* Component 1 */}
      <section className="section-padding feature_hero">
        <div className="container">
          <div className="d-flex flex-column flex-md-row gap-4 align-items-center mt-70 paddingx">
            <div className="col-md-6 col-lg-6 col-xs-12 col-sm-12  pt-md-0">
              <h1>Visitors Management</h1>
              <p className="fs-16">
                TimeTango’s Visitors Management feature is a seamless addition,
                designed to enhance security and streamline visitor experiences.
                supports businesses in managing employee attendance, visitor
                tracking, and real-time documentation. ensuring a secure and
                organized workplace.
              </p>
            </div>
            <div
              className="col-md-6 col-lg-6 col-xs-12 col-sm-12 "
              data-aos="fade-up-right"
            >
              <img
                src="assets/img/visitor_management/visitors_vanagement.svg"
                alt="Visitors Management"
                className="mx-auto w-100 h-100"
              />
            </div>
          </div>
        </div>
      </section>
      {/* component 2 */}
      <section className="component-padding bg-white">
        <div className="container d-flex flex-column gap-4">
          <div className=" text-start text-md-center paddingx">
            <h2 className="fs-1  fs-sm-4 text-dark">
              How TimeTango’s Visitors Management feature can transform your
              office operations
            </h2>
          </div>
          <div className="row align-items-center mb-4 paddingx">
            <div className="col-md-7 col-lg-7 col-xs-12 col-sm-12">
              <h3 className="fs-2">
                Enhanced Security with Pre-Approved Access
              </h3>
              <ul className="comp2para fs-16">
                <li>
                  Pre-Approval for Enhanced Control: TimeTango employee
                  attendance app allows HR and security teams to pre-approve
                  visitors, ensuring that only verified individuals have access.
                </li>
                <li>
                  Prompt Visitor Entry: When a pre-approved visitor arrives,
                  they gain swift entry. avoiding unnecessary wait times while
                  maintaining a secure check-in process.
                </li>
                <li>
                  Instant Notifications to Employees: Upon a visitor’s arrival,
                  the designated host receives immediate notifications.
                </li>
              </ul>
            </div>
            <div className="col-md-5 col-lg-5 col-xs-12 col-sm-12 ">
              <img
                src="assets/img/visitor_management/enhanced_security_with_pre_approved_access.svg"
                alt="Enhanced Security with Pre-Approved Access"
                className="mx-auto featureImg"
              />
            </div>
          </div>
          <div className="row align-items-center mb-4 mt-4 mt-md-0 paddingx">
            <div className="col-md-7 col-lg-7 col-xs-12 col-sm-12 order-md-2">
              <h3 className="fs-2 ">Comprehensive Documentation</h3>
              <ul className="comp2para fs-16">
                <li>
                  Complete Documentation Process: For every visitor entry,
                  TimeTango employee attendance application records detailed
                  documentation, including the visitor’s name, purpose of visit,
                  and other security-relevant information.
                </li>
                <li>
                  Effortless Retrieval of Records: The platform allows for
                  access, to visitor logs for inspection or auditing purposes to
                  facilitate adherence, to internal and external security
                  regulations.
                </li>
                <li>
                  Digital Storage for Secure Information Management: The
                  employee time tracking module integrates with visitor
                  documentation, storing records digitally to reduce paperwork
                  and ensure confidentiality.
                </li>
              </ul>
            </div>
            <div className="col-md-5 col-lg-5 col-xs-12 col-sm-12 order-md-1">
              <img
                src="assets/img/visitor_management/comprehensive_documentation.svg"
                alt="Comprehensive Documentation"
                className="mx-auto featureImg"
              />
            </div>
          </div>
          <div className="row align-items-center mb-4 mt-4 mt-md-0 paddingx">
            <div className="col-md-7 col-lg-7 col-xs-12 col-sm-12">
              <h3 className=" fs-2"> User-Friendly Visitor Check-In Process</h3>
              <ul className="comp2para fs-16">
                <li>
                  Simplified Check-In Process: Visitors can quickly check in via
                  TimeTango’s interface, which simplifies the process, requiring
                  minimal information and allowing for a prompt entry
                  experience.
                </li>
                <li>
                  Mobile-Friendly Options: TimeTango’s employee attendance app,
                  providing a smooth experience for visitors who can check in
                  through QR codes or digital kiosks.
                </li>
                <li>
                  Customizable Visitor Passes: The system allows for
                  customizable visitor badges, which can include photo IDs,
                  expiration times and host names, providing an extra layer of
                  security.
                </li>
              </ul>
            </div>
            <div className="col-md-5 col-lg-5 col-xs-12 col-sm-12">
              <img
                src="assets/img/visitor_management/user_friendly_visitor_check_in_process.svg"
                alt="User-Friendly Visitor Check-In Process"
                className="mx-auto featureImg"
              />
            </div>
          </div>
        </div>
      </section>
      {/* Component 3 */}
      <section className="section-padding ">
        <div className="container">
          <div className="row paddingx">
            <div className="col-md-12 col-lg-12 col-xs-12 col-sm-12 text-center">
              <h2 className="fs-2 fs-md-3 fs-sm-4">
                Benefits of Using Visitor Management feature
              </h2>
            </div>
          </div>

          <div className="tool mb-4 mt-4 paddingx">
            <h3 className="text-dark fs-4">Improved Workplace Security</h3>
            <ul>
              <li className="fs-16">
                Pre-approval, documentation and real-time tracking, help ensure
                a secure environment for both employees and visitors.
              </li>
            </ul>
          </div>
          <div className="tool mb-4 paddingx">
            <h3 className="text-dark fs-4">Streamlined Visitor Experience</h3>
            <ul>
              <li className="fs-16">
                TimeTango’s visitor management creates a seamless entry and exit
                process. leaving visitors with a positive impression.
              </li>
            </ul>
          </div>
          <div className="tool mb-4 paddingx">
            <h3 className="text-dark fs-4">Enhanced Employee Productivity:</h3>
            <ul>
              <li className="fs-16">
                By managing visitor flow efficiently, employees can focus on
                their core tasks without disruptions.
              </li>
            </ul>
          </div>
          <div className="tool mb-4 paddingx">
            <h3 className="text-dark fs-4">Compliance Assurance</h3>
            <ul>
              <li className="fs-16">
                The detailed records generated by the system make it easier for
                businesses to comply with safety, health and industry
                regulations.
              </li>
            </ul>
          </div>
          <div className="tool mb-4 paddingx">
            <h3 className="text-dark fs-4">Cost-Effective and Scalable</h3>
            <ul>
              <li className="fs-16">
                Designed to grow with your business, TimeTango’s solution is
                scalable and provides long-term value.
              </li>
            </ul>
            <p className="fs-16 mt-4">
              TimeTango’s Visitors Management feature combines security,
              convenience, and efficiency in a single platform.
            </p>
          </div>
        </div>
      </section>
      {/* componnent 4 */}
      <CallToActionSection></CallToActionSection>
    </>
  );
};

export default VisitorMangement;
