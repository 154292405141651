import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import CallToActionSection from "./commonComponent/CallToActionSection";
import "./common.css";
const FAQ = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const structuredData = {
    "@context": "https://schema.org",
    "@type": "Product",
    name: "FAQs About Timetango | Best HR Attendance Management Tool",
    image: "https://timetango.in/assets/img/hero/hero_combine.svg",
    description:
      "Have questions? Learn about Timetango’s time tracking software, attendance management tools, remote employee monitoring app, and more in our FAQs.",
    brand: {
      "@type": "Brand",
      name: "Time Tango",
      logo: "https://timetango.in/assets/logo3.svg",
      sameAs: [
        "https://www.facebook.com/people/TimeTango/61550584126267/",
        "https://www.linkedin.com/showcase/99884387/",
        "https://www.instagram.com/timetango.in/",
      ],
    },
    offers: {
      "@type": "Offer",
      url: "https://timetango.in",
      priceCurrency: "INR",
      price: "30",
    },
  };

  const faqs = [
    {
      question: "What is TimeTango?",
      answer:
        "Time Tango is a comprehensive Leave Management system designed to streamline attendance management, office check-ins, and check-outs. It simplifies tracking employee attendance, leave requests, and work hours, making it easier for organizations to manage their workforce efficiently.",
    },
    {
      question: "How does Time Tango help with attendance tracking?",
      answer:
        "Time Tango offers a digital attendance solution where employees can check in and out using a web or mobile application. It records timestamps automatically, reducing manual errors and ensuring accurate tracking of work hours.",
    },
    {
      question:
        "Is there a limit to the number of users or employees I can add to Time Tango?",
      answer:
        "No, there is no user limit. Time Tango is designed to scale with your business, whether you have a small team or a large organization.",
    },
    {
      question: "How easy is it to implement Time Tango in my organization?",
      answer:
        "Time Tango is user-friendly and can be implemented quickly. Our team provides onboarding assistance, training, and continuous support to ensure a smooth transition and effective use of the system.",
    },
    {
      question: "Does Time Tango offer a free trial or demo?",
      answer:
        "Yes, we offer a free trial period and live demo to help you understand the system's features and how it can benefit your business. Contact us to schedule a demo or start your free trial.",
    },
    {
      question: "How is pricing structured for Time Tango?",
      answer:
        "Time Tango offers flexible pricing plans based on the number of users and features required. Contact our sales team for a customized quote.",
    },
    {
      question:
        "How does TimeTango’s Detailed Compliance Reports help businesses stay compliant?",
      answer:
        "TimeTango's Detailed Compliance Reports provide businesses with accurate attendance data, track leave balances, and ensure adherence to company policies, helping maintain legal and regulatory compliance effortlessly.",
    },
    {
      question:
        "How does TimeTango ensure data security and privacy in compliance reporting?",
      answer:
        "TimeTango prioritizes the security of employee data by using encrypted access controls to protect attendance and compliance information from unauthorized access. The platform also offers controlled access, allowing businesses to limit data visibility to authorized personnel only, ensuring that sensitive information is handled securely and in accordance with privacy regulations.",
    },
    {
      question:
        "How does TimeTango support employee work-from-home (WFH) tracking?",
      answer:
        "TimeTango provides comprehensive WFH support by allowing managers to track employee work hours, location, and productivity from anywhere. The system monitors approved devices for remote employees, ensuring secure check-ins and check-outs. It offers real-time visibility into work locations and activity, promoting accountability and trust while helping to track productivity effectively, even when employees are working remotely.",
    },
    {
      question: "What should I do if I forget to mark check-in and check-out?",
      answer:
        "If you forget to mark your attendance in TimeTango, you can submit a revision request by specifying the date and your check-in and check-out times in the system. Once the request is reviewed and approved by your manager or HR, you will receive an email notification confirming the update.",
    },
    {
      question: "How does TimeTango simplify employee leave management?",
      answer:
        "TimeTango streamlines leave management by integrating attendance and leave tracking into one unified platform. Employees can easily mark attendance, apply for leave, and view their leave balances. Managers benefit from automated leave calculations, customizable approval workflows, and real-time notifications for leave requests.",
    },
    {
      question: "How does TimeTango track and calculate leave balances?",
      answer:
        "Time Tango tracks and calculates leave balances by automatically recording leave requests, approvals, and employee attendance, updating the remaining balances in real-time based on the company's leave policies and rules.",
    },
    {
      question: "How does TimeTango help with compliance and record-keeping?",
      answer:
        "TimeTango maintains comprehensive historical records of attendance and leave, making it easier to stay compliant with regulatory requirements. These records are accessible for audits, ensuring accurate tracking and reducing the risk of discrepancies or mismanagement in leave management.",
    },
    {
      question: "What benefits does TimeTango offer for HR teams and managers?",
      answer:
        "TimeTango provides HR teams and managers with real-time insights into attendance and leave trends, which helps improve workforce planning. By minimizing absenteeism, reducing administrative workloads, and offering detailed reports, TimeTango helps make data-driven decisions, improves resource planning, and enhances overall productivity.",
    },
    {
      question: "How does TimeTango’s Employee Activity Tracking work?",
      answer:
        "TimeTango tracks employee activity in real time by monitoring system usage during work hours. It provides managers with insights into how time is spent, including idle time and periods of activity, to help identify areas for improvement. Alerts can also be set for irregular patterns, such as extended idle periods or unauthorized app usage, enabling proactive management.",
    },
    {
      question: "Is TimeTango’s Employee Tracking Tool difficult to use?",
      answer:
        "No, TimeTango is designed with a user-friendly interface that makes it easy for both managers and employees to navigate.",
    },
    {
      question: "How does TimeTango ensure employee privacy?",
      answer:
        "Time Tango ensures employee privacy by securely storing attendance data with encryption, offering controlled access for managers, and allowing employees to view and manage their own information without compromising sensitive details.",
    },
    {
      question: "Can TimeTango help improve team efficiency?",
      answer:
        "Yes, TimeTango provides valuable insights into productivity trends, which can help managers and teams identify areas for improvement. By monitoring app and website usage, along with tracking idle times, TimeTango helps businesses optimize workflows and ensure that employees remain focused on their tasks, boosting overall efficiency.",
    },
  ];

  const [activeIndex, setActiveIndex] = useState(null);

  const toogleAnswer = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <>
      <Helmet>
        <title>
        FAQs About Timetango | Best HR Attendance Management Tool
        </title>
        <meta
          name="description"
          content="Have questions? Learn about Timetango’s time tracking software, attendance management tools, remote employee monitoring app, and more in our FAQs."
        />
        <meta
          property="og:title"
          content="FAQs About Timetango | Best HR Attendance Management Tool"
        />
        <meta
          property="og:description"
          content="Have questions? Learn about Timetango’s time tracking software, attendance management tools, remote employee monitoring app, and more in our FAQs."
        />
        <meta
          property="og:image"
          content="https://timetango.in/assets/logo3.svg"
        />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://timetango.in/" />
        <link rel="canonical" href="https://timetango.in/" />
        <meta
          property="og:site_name"
          content="Timetango"
        />
        <meta name="language" content="english" />
        <meta
          name="google-site-verification"
          content="HeqJ8arMIZIDpCmRH1zlrNu601VS7Wp92JoTy_9bARM"
        ></meta>
        <script type="application/ld+json">
          {JSON.stringify(structuredData)}
        </script>
      </Helmet>
      <section className="section-padding bg-white">
        <div className="container">
          <div className="d-flex flex-column gap-4 align-items-center mt-4 paddingx">
            <div>
              <h1 className="text-center">FAQs</h1>
            </div>
          </div>
          <div className="faq-section d-flex flex-column gap-4  align-items-center justify-content-center mt-4">
            {faqs.map((faq, index) => (
              <div
                key={index}
                className="faq-item w-100 d-flex flex-column paddingx align-items-center justify-content-center"
              >
                <div
                  className="faq-question d-flex justify-content-between align-items-center faq-questions w-100"
                  onClick={() => toogleAnswer(index)}
                >
                  <p className="fs-18 fw-semibold">{faq.question}</p>
                  <p className="fs-18 fw-semibold">
                    {activeIndex === index ? "-" : "+"}
                  </p>
                </div>
                {activeIndex === index && (
                  <div className="faq-answer  faq-answers fs-18 fs-semibold w-100">
                    {faq.answer}
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      </section>
      {/* componnent 2 */}
      <CallToActionSection></CallToActionSection>
    </>
  );
};

export default FAQ;
