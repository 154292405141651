import { useEffect } from "react";
import { Helmet } from "react-helmet";
import "./common.css";
import FormatQuoteIcon from "@mui/icons-material/FormatQuote";
import CallToActionSection from "./commonComponent/CallToActionSection";
const AboutUs = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const structuredData = {
    "@context": "https://schema.org",
    "@type": "Product",
    "name": "About Timetango | Best HRMS & Attendance Tracking Software in India",
    "image": "https://timetango.in/assets/img/hero/hero_combine.svg",
    "description": "Discover Timetango, India’s leading workforce attendance management system, offering advanced time tracking and productivity software for all businesses.",
    "brand": {
      "@type": "Brand",
      "name": "Time Tango",
      "logo": "https://timetango.in/assets/logo3.svg",
      "sameAs": [
        "https://www.facebook.com/people/TimeTango/61550584126267/",
        "https://www.linkedin.com/showcase/99884387/",
        "https://www.instagram.com/timetango.in/"
      ]
    },
    "offers": {
      "@type": "Offer",
      "url": "https://timetango.in",
      "priceCurrency": "INR",
      "price": "30"
    }
  };

  return (
    <>
      <Helmet>
        <title>
        About Timetango | Best HRMS & Attendance Tracking Software in India
        </title>
        <meta
          name="description"
          content="Discover Timetango, India’s leading workforce attendance management system, offering advanced time tracking and productivity software for all businesses."
        />
        <meta
          property="og:title"
          content="About Timetango | Best HRMS & Attendance Tracking Software in India"
        />
        <meta
          property="og:description"
          content="Discover Timetango, India’s leading workforce attendance management system, offering advanced time tracking and productivity software for all businesses."
        />
        <meta
          property="og:image"
          content="https://timetango.in/assets/logo3.svg"
        />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://timetango.in/" />
        <link rel="canonical" href="https://timetango.in/" />
        <meta
          property="og:site_name"
          content="Timetango"
        />
        <meta name="language" content="english" />
        <meta
          name="google-site-verification"
          content="HeqJ8arMIZIDpCmRH1zlrNu601VS7Wp92JoTy_9bARM"
        ></meta>
        <script type="application/ld+json">
          {JSON.stringify(structuredData)}
        </script>
      </Helmet>
      <main>
        <section className=" section-padding about_bg">
          <div className="container">
            <div className="row d-flex align-items-center paddingx">
              <div className="col-md-10 col-lg-10 col-xs-12 col-xs-12 mx-auto component-padding">
                <h1 className="text-center">
                  Let's know <span className="text-purple">Timetango</span>
                </h1>
                <p className="text-center fs-16">
                  Welcome to TimeTango, in today’s fast-paced business
                  landscape, we recognize the critical importance of an
                  Efficient, Accurate and User-friendly Employee Attendance
                  Management System. That's why we developed TimeTango. To
                  support HR departments in simplifying operations and enabling
                  companies of all scales to achieve their capabilities.
                </p>
              </div>
              <div className="col-md-10 col-lg-10 col-xs-12 col-sm-12 mx-auto">
                <div className="about_banner_img d-flex justify-content-center">
                  <img
                    src="assets/img/feature/about_us.png"
                    alt="about Us"
                    className="w-100 h-100"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="container pt-100">
          <div className="row">
            <div className="col-md-10 col-lg-10 col-xs-12 col-sm-12 mx-auto pt-50 paddingx">
              <div className="card shadow-sm p-3 w-100">
                <p className="text-dark fs-18 ">
                  <FormatQuoteIcon className="quote" />
                  At TimeTango, our mission is to simplify employee attendance
                  management, empower employees and support business growth by
                  offering a seamless employee attendance application. By making
                  employee attendance and employee time tracking more
                  straightforward, we can help businesses unlock more time to
                  focus on their core activities.
                </p>
              </div>
            </div>
          </div>
        </section>
        <section className="component-padding bg-white">
          <div className="container">
            <div className="row align-items-center paddingx">
              <div className="col-md-6 col-lg-6">
                {/* <div className="col-md-10"> */}
                <h2 className="text-dark">Our Vision</h2>
                <p className="text-dark fs-16">
                  At TimeTango, our vision is to simplify employee time
                  management and address the pain points of HR professionals. We
                  have recognized the gaps in traditional attendance systems and
                  set out to build a better solution. Over the years, we’ve
                  grown into a trusted partner for businesses worldwide, helping
                  them save time, reduce errors, enhance productivity and boost
                  employee morale.
                </p>
                {/* </div> */}
              </div>
              <div className="col-md-6 col-lg-6 d-flex justify-content-center align-items-center mx-auto mt-4 mt-md-0">
                <img
                  src="assets/img/aboutus/our_vision.webp"
                  alt="Our Vision"
                  className="wf-80"
                />
              </div>
            </div>
          </div>
        </section>
        <section className="component-padding bg-smooth">
          <div className="container px-md-0">
            <div className="row paddingx">
              <h2 className="text-dark text-center">Our Values</h2>
            </div>
            <div className="row justify-content-evenly paddingx">
              <div className="col-lg-3 col-md-6 col-sm-6">
                <div className="text-center">
                  <div className="feature_img">
                    <img
                      src="assets/img/aboutus/innovation.svg"
                      alt="Innovation"
                    />
                  </div>
                  <h3 className="fs-5">Innovation</h3>
                  <p className="text-center fs-16">
                    At our core we are advocates of utilizing technology to
                    introduce approaches, to managing employee time tracking and
                    HR operations.
                  </p>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-sm-6">
                <div className="text-center">
                  <div className="feature_img">
                    <img
                      src="assets/img/aboutus/integrity.svg"
                      alt="Integrity"
                    />
                  </div>
                  <h3 className="fs-5">Integrity</h3>
                  <p className="text-center fs-16">
                    At Time Tango we prioritize integrity ensuring our clients
                    have confidence, in the reliability of our services through
                    a commitment, to honesty and transparency.
                  </p>
                </div>
              </div>
              <div className="col-lg-3 col-sm-6 col-md-6 ">
                <div className="text-center">
                  <div className="feature_img ">
                    <img
                      src="assets/img/aboutus/clients_success.svg"
                      alt="Clients Success"
                    />
                  </div>
                  <h3 className="fs-5">Clients Success</h3>
                  <p className="text-centerfs-16">
                    We are committed, to assisting our clients in achieving
                    success by offering tailored assistance and advice to
                    guarantee a fruitful interaction, with our employee time
                    management app.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="component-padding bg-white">
          <div className="container">
            <div className="row paddingx">
              <div className="col-md-12 col-lg-12">
                <h2 className="text-dark">Join Us on the Journey</h2>
                <p className="text-dark fs-16">
                  Whether you're a company, or an established corporation. Time
                  Tango is ready to assist you every step of the way. Join our
                  business community, See how utilizing a more efficient
                  employee attendance app and employee hour tracking software
                  can transform your HR operations to new heights!
                </p>
                <p className="fs-16 text-dark">
                  Let’s work together to optimize time management and elevate
                  your HR practices.
                </p>
              </div>
            </div>
          </div>
        </section>
        <CallToActionSection />
      </main>
    </>
  );
};

export default AboutUs;
