import { Helmet } from "react-helmet";
import { useEffect } from "react";
import "./common.css";
import ContactUsForm from "../ContactUsForm";
const ContactUs = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const structuredData = {
    "@context": "https://schema.org",
    "@type": "Product",
    name: "Contact Timetango | Employee Attendance & Time Tracking Solutions",
    image: "https://timetango.in/assets/img/hero/hero_combine.svg",
    description:
      "Reach out to Timetango for the best HRMS software in India. Manage employee attendance, time tracking, and productivity with our expert solutions.",
    brand: {
      "@type": "Brand",
      name: "Time Tango",
      logo: "https://timetango.in/assets/logo3.svg",
      sameAs: [
        "https://www.facebook.com/people/TimeTango/61550584126267/",
        "https://www.linkedin.com/showcase/99884387/",
        "https://www.instagram.com/timetango.in/",
      ],
    },
    offers: {
      "@type": "Offer",
      url: "https://timetango.in",
      priceCurrency: "INR",
      price: "30",
    },
  };
  return (
    <>
      <Helmet>
        <title>
        Contact Timetango | Employee Attendance & Time Tracking Solutions
        </title>
        <meta
          name="description"
          content="Reach out to Timetango for the best HRMS software in India. Manage employee attendance, time tracking, and productivity with our expert solutions."
        />
        <meta
          property="og:title"
          content="Contact Timetango | Employee Attendance & Time Tracking Solutions"
        />
        <meta
          property="og:description"
          content="Reach out to Timetango for the best HRMS software in India. Manage employee attendance, time tracking, and productivity with our expert solutions."
        />
        <meta
          property="og:image"
          content="https://timetango.in/assets/logo3.svg"
        />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://timetango.in/" />
        <link rel="canonical" href="https://timetango.in/" />
        <meta
          property="og:site_name"
          content="Timetango"
        />
        <meta name="language" content="english" />
        <meta
          name="google-site-verification"
          content="HeqJ8arMIZIDpCmRH1zlrNu601VS7Wp92JoTy_9bARM"
        ></meta>
        <script type="application/ld+json">
          {JSON.stringify(structuredData)}
        </script>
      </Helmet>

      <main>
        <section className="section-padding bg-white pt-4 mt-80">
          <div className="container">
            <div className="row">
              <div className="col-md-12 col-lg-12 col-xs-12 col-sm-12">
                <div className="row">
                  <h3 className="text-dark text-center">Contact Us</h3>
                  <p className="text-center">
                    Have a question, feel free to buzz&nbsp;us!
                  </p>
                </div>
              </div>
              <div className="col-md-10 col-lg-10 mx-auto">
                <ContactUsForm />
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
};

export default ContactUs;
