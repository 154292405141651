import React, { useEffect, createContext, useContext, useState } from 'react';
import { useLocation } from 'react-router-dom';

// Create a context
const TawkToContext = createContext();

// Custom hook to use the TawkTo context
export const useTawkTo = () => useContext(TawkToContext);

// TawkToProvider Component
export const TawkToProvider = ({ tawkToId, children }) => {
  const location = useLocation(); // Get current path
  const [isHidden, setIsHidden] = useState(true); // Track widget visibility (default hidden)
  const [isTawkLoaded, setIsTawkLoaded] = useState(false); // Ensure script isn't reloaded

  // Paths where the widget should be applied
  const applyPaths = ['/login', '/' , "/company-register" , "/offer","/leave-mangement", "/activity-tracking", "/real-time-insight","/wfh-support","/compliences-report","/visitor-manegement","/about-us","/pricing","/faq","/contact-us"];

  const removeTawkScripts = () => {
    const existingScript = document.querySelector(`script[src*="${tawkToId}"]`);
    if (existingScript) existingScript.remove();

    const tawkIframes = document.querySelectorAll('iframe[title="chat widget"]');
    tawkIframes.forEach((iframe) => iframe.remove());
  };

  const applyTawkScripts = () => {
    if (isTawkLoaded) return; // If already loaded, skip execution
  
    const script = document.createElement('script');
    script.src = `https://embed.tawk.to/${tawkToId}/1hn3195me`;
    script.async = true;
    script.crossOrigin = '*';
  
    script.onload = () => {
      window.Tawk_API = window.Tawk_API || {};
      setIsTawkLoaded(true); // Mark the script as loaded
  
      // Handle widget visibility based on current path
      if (isHidden) {
        window.Tawk_API.hideWidget?.();
      } else {
        window.Tawk_API.showWidget?.();
      }
    };
  
    // Check if the script already exists; if not, append it to the head
    const existingScript = document.querySelector(`script[src*="${tawkToId}"]`);
    if (!existingScript) {
      document.head.appendChild(script);
    }
  
    // Handle existing iframes (e.g., ensuring they are re-added or re-attached if necessary)
    const tawkIframes = document.querySelectorAll('iframe[title="chat widget"]');
    tawkIframes.forEach((iframe) => {
      // Re-append iframe to the same parent to ensure it's active
      iframe.parentNode?.appendChild(iframe);
    });
  };

  useEffect(() => {
    const isCurrentPathVisible = applyPaths.includes(location.pathname);
    setIsHidden(!isCurrentPathVisible); // Hide if not in applyPaths

    if (isCurrentPathVisible) {
      // Check if page has been reloaded already for this session
      if (!sessionStorage.getItem('hasReloaded')) {
        sessionStorage.setItem('hasReloaded', 'true'); // Set the flag
        window.location.reload(); // Reload the window
      } else {
        applyTawkScripts(); // Apply scripts when on allowed paths
      }
    } else {
      removeTawkScripts(); // Remove scripts when on other paths
      if (window.Tawk_API) window.Tawk_API.hideWidget?.();
    }

    return () => {
      if (!isCurrentPathVisible) removeTawkScripts(); // Clean up if switching to non-allowed path
    };
  }, [location.pathname]);

  return <TawkToContext.Provider value={null}>{children}</TawkToContext.Provider>;
};