import "./common.css";
import Aos from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";
import { Helmet } from "react-helmet";
import CallToActionSection from "./commonComponent/CallToActionSection";
const WorkFromHome = () => {
  useEffect(() => {
    Aos.init({
      duration: 100,
      once: true,
    });
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const structuredData = {
    "@context": "https://schema.org",
    "@type": "Product",
    name: "Remote Employee Monitoring App for WFH Support | Timetango",
    image: "https://timetango.in/assets/img/hero/hero_combine.svg",
    description:
      "Manage remote teams efficiently with Timetango’s WFH support. Best time tracking app & attendance management software for remote employee monitoring.",
    brand: {
      "@type": "Brand",
      name: "Time Tango",
      logo: "https://timetango.in/assets/logo3.svg",
      sameAs: [
        "https://www.facebook.com/people/TimeTango/61550584126267/",
        "https://www.linkedin.com/showcase/99884387/",
        "https://www.instagram.com/timetango.in/",
      ],
    },
    offers: {
      "@type": "Offer",
      url: "https://timetango.in",
      priceCurrency: "INR",
      price: "30",
    },
  };

  return (
    <>
      <Helmet>
        <title>
        Remote Employee Monitoring App for WFH Support | Timetango
        </title>
        <meta
          name="description"
          content="Manage remote teams efficiently with Timetango’s WFH support. Best time tracking app & attendance management software for remote employee monitoring."
        />
        <meta
          property="og:title"
          content="Remote Employee Monitoring App for WFH Support | Timetango"
        />
        <meta
          property="og:description"
          content="Manage remote teams efficiently with Timetango’s WFH support. Best time tracking app & attendance management software for remote employee monitoring."
        />
        <meta
          property="og:image"
          content="https://timetango.in/assets/logo3.svg"
        />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://timetango.in/" />
        <link rel="canonical" href="https://timetango.in/" />
        <meta
          property="og:site_name"
          content="Timetango"
        />
        <meta name="language" content="english" />
        <meta
          name="google-site-verification"
          content="HeqJ8arMIZIDpCmRH1zlrNu601VS7Wp92JoTy_9bARM"
        ></meta>
        <script type="application/ld+json">
          {JSON.stringify(structuredData)}
        </script>
      </Helmet>
      {/* Component 1 */}
      <section className="section-padding feature_hero">
        <div className="container">
          <div className="d-flex flex-column flex-md-row gap-4 align-items-center mt-70 paddingx">
            <div className="col-md-6 col-lg-6 col-xs-12 col-sm-12  pt-md-0">
              <h1 className="fs-1 fs-md-3 fs-sm-4">
                Employee Work From Home Support
              </h1>
              <p className="fs-16">
                TimeTango employee management system offers comprehensive WFH
                support to keep you connected with remote team. With employee
                time tracking capabilities, TimeTango monitors approved devices
                for employees working from home, allowing for seamless
                check-in/check-out and daily tracking.
              </p>
              <p className="fs-16">
                Our employee attendance app ensures that your team is securely
                connected, no matter their location and allows you to track
                productivity from anywhere.
              </p>
            </div>
            <div
              className="col-md-6 col-lg-6 col-xs-12 col-sm-12 "
              data-aos="fade-up-right"
            >
              <img
                src="assets/img/wfh/employee_woark_from_home_support.svg"
                alt="Employee Work From Home Support"
                className="mx-auto w-100 h-100"
              />
            </div>
          </div>
        </div>
      </section>

      {/* Component 2 */}
      <section className="component-padding bg-white">
        <div className="container">
          <div className="row align-items-center mb-4 paddingx">
            <div className="col-md-7 col-lg-7 col-xs-12 col-sm-12">
              <h2 className="fs-2">Location-Based Attendance</h2>
              <ul className="comp2para fs-16">
                <li>
                  Stay informed about your team members' locations with
                  TimeTango's reliable location tracking. This feature enhances
                  accountability, providing peace of mind by ensuring that
                  employees are connected and using verified devices.
                </li>
              </ul>
            </div>
            <div className="col-md-5 col-lg-5 col-xs-12 col-sm-12">
              <img
                src="assets/img/wfh/location_based_attendance.svg"
                alt="Location-Based Attendance"
                className="mx-auto featureImg"
              />
            </div>
          </div>
          <div className="row align-items-center mb-4 paddingx">
            <div className="col-md-7 col-lg-7 col-xs-12 col-sm-12 order-md-2 mt-4 mt-md-0">
              <h2 className="fs-2 ">Track Daily Work Hours</h2>
              <ul className="comp2para fs-16">
                <li>
                  With TimeTango’s employee hour tracking software, you get a
                  full view of each team member’s workday from start to finish.
                  The system records check-in and check-out times, providing an
                  accurate overview of hours worked.
                </li>
                <li>
                  Generate and export detailed reports to seamlessly integrate
                  with your payroll system, eliminating manual calculations and
                  enhancing payroll accuracy. This feature transforms TimeTango
                  into an ideal employee timesheet software, making time
                  tracking easy and efficient.
                </li>
              </ul>
            </div>
            <div className="col-md-5 col-lg-5 col-xs-12 col-sm-12 order-md-1">
              <img
                src="assets/img/wfh/track_daily_work_hours.svg"
                alt="Track Daily Work Hours"
                className="mx-auto featureImg"
              />
            </div>
          </div>
          <div className="row align-items-center mb-4 paddingx">
            <div className="col-md-7 col-lg-7 col-xs-12 col-sm-12 mt-4 mt-md-0">
              <h2 className="fs-2">Detailed Work Hour Analysis</h2>
              <ul className="comp2para fs-16">
                <li>
                  TimeTango’s employee time tracking features, provide insights
                  into the actual time employees spend actively working.
                </li>
                <li>
                  TimeTango employee time management software helps
                  organisations identify high- performers and opportunities for
                  improvement, supporting better team performance.
                </li>
              </ul>
            </div>
            <div className="col-md-5 col-lg-5 col-xs-12 col-sm-12">
              <img
                src="assets/img/wfh/detailed_work_hour_analysis.svg"
                alt="Detailed Work Hour Analysis"
                className="mx-auto featureImg"
              />
            </div>
          </div>
          <div className="row align-items-center mb-4 paddingx">
            <div className="col-md-7 col-lg-7 col-xs-12 col-sm-12 order-md-2 mt-4 mt-md-0">
              <h2 className="fs-2 ">Real-Time Project and Task Monitoring</h2>
              <ul className="comp2para fs-16">
                <li>
                  Stay in tune with your team’s progress through TimeTango’s
                  real-time project monitoring. you can access details on
                  projects, tasks, apps and websites your employees are using.
                </li>
                <li>
                  Whether it’s a critical project or daily activities,
                  TimeTango’s real-time tracking helps maintain productivity
                  across the team.
                </li>
              </ul>
            </div>
            <div className="col-md-5 col-lg-5 col-xs-12 col-sm-12 order-md-1">
              <img
                src="assets/img/wfh/real-time_project_and_ask_monitoring.svg"
                alt="Real-Time Project and Task Monitoring"
                className="mx-auto featureImg"
              />
            </div>
          </div>
          <div className="row align-items-center mb-4 paddingx">
            <div className="col-md-7 col-lg-7 col-xs-12 col-sm-12 mt-4 mt-md-0">
              <h2 className="fs-2">App and Website Usage Tracking</h2>
              <ul className="comp2para fs-16">
                <li></li>
                <li>
                  TimeTango’s real-time insights offer a clear view of the apps
                  and websites your remote employee used. giving you a better
                  picture of work habits and productivity. This level of
                  employee hour tracking software ensures that resources are
                  used efficiently, empowering your team to stay on task, meet
                  deadlines and work towards shared goals.
                </li>
              </ul>
            </div>
            <div className="col-md-5 col-lg-5 col-xs-12 col-sm-12">
              <img
                src="assets/img/wfh/app_website_usage_tracking.svg"
                alt="App and Website Usage Tracking"
                className="mx-auto featureImg"
              />
            </div>
          </div>
        </div>
      </section>
      {/* Component 3*/}
      <section className="section-padding ">
        <div className="container">
          <div className="row paddingx">
            <div className="col-md-12 col-lg-12 col-xs-12 col-sm-12 text-center">
              <h2 className="fs-2 fs-md-3 fs-sm-4">
                Why Choose TimeTango for Remote Team Management?
              </h2>
              <p className="fs-16 mb-4">
                TimeTango provides a powerful, all-in-one solution for managing
                remote teams, designed specifically for today’s dynamic work
                environments. Here’s why it stands out:
              </p>
            </div>
          </div>
          <div className="tool mb-4 paddingx">
            <h3 className="text-dark fs-4">Comprehensive Tracking</h3>
            <ul>
              <li className="fs-16">
                TimeTango combines device tracking, employee time tracking and
                real-time monitoring to ensure accountability and secure,
                reliable remote work.
              </li>
            </ul>
          </div>
          <div className="tool mb-4 paddingx">
            <h3 className="text-dark fs-4">Enhanced Productivity</h3>
            <ul>
              <li className="fs-16">
                With real-time insights into tasks, projects, and app usage,
                TimeTango empowers managers to make data-driven decisions, boost
                productivity and optimize workflow.
              </li>
            </ul>
          </div>
          <div className="tool mb-4 paddingx">
            <h3 className="text-dark fs-4">Robust Reporting and Analysis</h3>
            <ul>
              <li className="fs-16">
                Detailed, exportable reports help managers track hours, analyze
                performance, and manage payroll seamlessly reducing
                administrative load.
              </li>
            </ul>
          </div>
          <div className="tool mb-4 paddingx">
            <h3 className="text-dark fs-4">Customizable and Scalable</h3>
            <ul>
              <li className="fs-16">
                TimeTango adapts to your unique business needs with customizable
                settings and flexible integrations, making it easy to scale as
                your team grows.
              </li>
            </ul>
          </div>
          <div className="tool mb-4 paddingx">
            <h3 className="text-dark fs-4">Boosted Security</h3>
            <ul>
              <li className="fs-16">
                By tracking approved devices and employee locations, TimeTango
                enhances security, ensuring that sensitive data is protected in
                any work setting.
              </li>
            </ul>
          </div>
          <div className="tool mb-4 paddingx">
            <p className="fs-16">
              With TimeTango, managing remote teams becomes simpler and more
              effective with powerful employee time management software tailored
              to meet modern workforce needs.
            </p>
          </div>
        </div>
      </section>
      {/* componnent 4 */}
      <CallToActionSection></CallToActionSection>
    </>
  );
};

export default WorkFromHome;
