import "./common.css";
import Aos from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
const ActivityTracking = () => {
  useEffect(() => {
    Aos.init({
      duration: 1000,
      once: true,
    });
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const structuredData = {
    "@context": "https://schema.org",
    "@type": "Product",
    name: "Employee Worktime Tracker Software | Activity Tracking by Timetango",
    image: "https://timetango.in/assets/img/hero/hero_combine.svg",
    description:
      "Track employee productivity with Timetango’s advanced activity tracking software. Best time tracking & real-time employee monitoring tool for all workplaces.",
    brand: {
      "@type": "Brand",
      name: "Time Tango",
      logo: "https://timetango.in/assets/logo3.svg",
      sameAs: [
        "https://www.facebook.com/people/TimeTango/61550584126267/",
        "https://www.linkedin.com/showcase/99884387/",
        "https://www.instagram.com/timetango.in/",
      ],
    },
    offers: {
      "@type": "Offer",
      url: "https://timetango.in",
      priceCurrency: "INR",
      price: "30",
    },
  };

  return (
    <>
      <Helmet>
        <title>
        Employee Worktime Tracker Software | Activity Tracking by Timetango
        </title>
        <meta
          name="description"
          content="Track employee productivity with Timetango’s advanced activity tracking software. Best time tracking & real-time employee monitoring tool for all workplaces."
        />
        <meta
          property="og:title"
          content="Employee Worktime Tracker Software | Activity Tracking by Timetango"
        />
        <meta
          property="og:description"
          content="Track employee productivity with Timetango’s advanced activity tracking software. Best time tracking & real-time employee monitoring tool for all workplaces."
        />
        <meta
          property="og:image"
          content="https://timetango.in/assets/logo3.svg"
        />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://timetango.in/" />
        <link rel="canonical" href="https://timetango.in/" />
        <meta
          property="og:site_name"
          content="Timetango"
        />
        <meta name="language" content="english" />
        <meta
          name="google-site-verification"
          content="HeqJ8arMIZIDpCmRH1zlrNu601VS7Wp92JoTy_9bARM"
        ></meta>
        <script type="application/ld+json">
          {JSON.stringify(structuredData)}
        </script>
      </Helmet>
      {/* component 1 */}
      <section className="section-padding feature_hero">
        <div className="container ">
          <div className="d-flex flex-column flex-md-row gap-4 align-items-center paddingx mt-70">
            {" "}
            <div className="col-md-6 col-lg-6 col-xs-12 col-sm-12  pt-md-0">
              <h1>
                Introducing TimeTango’s Advanced Employee Activity Tracking
              </h1>
              <p className="fs-16">
                In today&#39;s fast-paced work environment, keeping track of
                productivity are vital. TimeTango’s Employee Activity Tracking
                Feature, offers a comprehensive way to manage and monitor
                workforce productivity seamlessly.
              </p>
              <p className="fs-16">
                This powerful tool combines advanced tracking capabilities with
                user-friendly features. providing live insights on idle time.
                Whether you are aiming to enhance employee accountability or
                optimize team efficiency, Employee Activity Monitoring tool is
                designed to fulfill these objectives.
              </p>
            </div>
            <div className="col-12 col-md-6" data-aos="fade-up-right">
              <img
                src="assets/img/activity_tracking/advanced_employee_activity_tracking.svg"
                alt="Advanced employee activity tracking"
                className="mx-auto w-100 h-100"
              />
            </div>
          </div>
        </div>
      </section>

      {/* component 2 */}
      <section className="component-padding bg-white">
        <div className="container">
          <div className="row align-items-center mb-4 paddingx">
            <div className="col-md-7 col-lg-7 col-xs-12 col-sm-12">
              <h2 className="fs-2">Real-time tracking and Active Hours</h2>
              <ul className="fs-16">
                <li>
                  TimeTango&#39;s employee tracking tool offers real-time
                  tracking, giving managers real-time insight into periods when
                  employees might be inactive.
                </li>
                <li>
                  Supports greater accountability by showing managers how long
                  each employee is active, helping to identify any potential
                  issues.
                </li>
                <li>
                  It helps in understanding productivity trends and allowing
                  teams to work on improving efficiency.
                </li>
              </ul>
            </div>
            <div className="col-md-5 col-lg-5 col-xs-12 col-sm-12">
              <img
                src="assets/img/activity_tracking/real-time_tracking.svg"
                alt="Real-time tracking"
                className="mx-auto featureImg"
              />
            </div>
          </div>
          <div className="row align-items-center mb-4 paddingx">
            <div className="col-md-7 col-lg-7 col-xs-12 col-sm-12 order-md-2 mt-4 mt-md-0">
              <h2 className="fs-2 ">App and Website Monitoring</h2>
              <ul className="comp2para fs-16">
                <li>
                  TimeTango employee tracking app monitors all the applications
                  and websites accessed during work hours and giving admins a
                  clear view of how time is spent.
                </li>
                <li>
                  Managers can effectively track how employees use apps and
                  websites through reports to ensure that work hours are spent
                  on tasks.
                </li>
                <li>
                  This functionality enables businesses to help their employees
                  effectively manage their time and stay concentrated, on their
                  duties.
                </li>
              </ul>
            </div>
            <div className="col-md-5 col-lg-5 col-xs-12 col-sm-12 order-md-1">
              <img
                src="assets/img/activity_tracking/app_and_website_monitoring.svg"
                alt="App and Website Monitoring"
                className="mx-auto featureImg"
              />
            </div>
          </div>
          <div className="row align-items-center mb-4 paddingx">
            <div className="col-md-7 col-lg-7 col-xs-12 col-sm-12 mt-4 mt-md-0">
              <h2 className=" fs-2">Real-Time Notifications</h2>
              <ul className="comp2para fs-16">
                <li>
                  Managers can set up real-time alerts to notify them of
                  irregular patterns such as extended idle times or unauthorized
                  app usage.
                </li>
                <li>
                  This feature enables proactive management by catching
                  potential distractions before they become productivity issues.
                </li>
                <li>
                  These alerts ensure that productivity stays consistent without
                  the need for constant supervision.
                </li>
              </ul>
            </div>
            <div className="col-md-5 col-lg-5 col-xs-12 col-sm-12">
              <img
                src="assets/img/activity_tracking/real-time_notifications.svg"
                alt="Real-Time Notifications"
                className="mx-auto featureImg"
              />
            </div>
          </div>
        </div>
      </section>

      {/* component 3 */}
      <section className="section-padding ">
        <div className="container">
          <div className="row paddingx">
            <div className="col-md-12 col-lg-12 col-xs-12 col-sm-12 text-center">
              <h2 className="fs-2 fs-md-3 fs-sm-4">
                How TimeTango Employee Tracking Tool Stands Out
              </h2>
            </div>
          </div>

          <div className="tool mb-4 mt-4 mt-md-0 paddingx">
            <h3 className="text-dark fs-4">Live Update</h3>
            <ul>
              <li className="fs-16">
                TimeTango employee monitoring software offers reporting features
                that allow managers to gain insights, into the productivity
                trends of both individual employees and teams efficiently
                pinpointing areas for improvement, with ease.
              </li>
              <li className="fs-16">
                Managers have the option to check reports daily or opt for
                monthly updates to monitor progress consistently over time.
              </li>
            </ul>
          </div>

          <div className="tool mb-4 paddingx">
            <h3 className="text-dark fs-4">User-Friendly Interface</h3>
            <ul>
              <li className="fs-16">
                Time Tango is created with a focus, on simplicity. Features a
                user interface that makes it easy for both employees and
                managers to navigate without any steep learning curves.
              </li>
              <li className="fs-16">
                Navigating through its user design makes it easy to uncover
                information and tailor notifications and privacy preferences to
                suit your needs effortlessly.
              </li>
            </ul>
          </div>
          <div className="tool mb-4 paddingx">
            <h3 className="text-dark fs-4">Privacy and Security</h3>
            <ul>
              <li className="fs-16">
                Security is a top priority for TimeTango, and all data collected
                is protected by robust encryption protocols to ensure privacy
                and compliance with regulatory standards.
              </li>
              <li className="fs-16">
                The system enables businesses to personalize tracking settings
                to ensure that only necessary information is gathered.
              </li>
            </ul>
          </div>
          <div className="tool mb-4 paddingx">
            <h3 className="text-dark fs-4"> Boost Productivity</h3>
            <ul>
              <li className="fs-16">
                TimeTango provides a solution for boosting employee efficiency
                and transparency in the workplace by tracking time and app usage
                while providing immediate feedback to foster accountability and
                productivity, within organizations.
              </li>
              <li className="fs-16">
                It prioritizes privacy and encourages employees to take control
                of their own schedules by offering a comprehensive time tracking
                solution that promotes a healthy work culture.
              </li>
            </ul>
          </div>
        </div>
      </section>

      {/* Component 4 */}
      <div className="bg-gradient section-padding">
        <div className="container">
          <div className=" col-10 text-center mx-auto mb-4">
            <h3 className=" fs-2">
              Optimize Your Workforce with TimeTango Today!
            </h3>
            <p style={{ fontSize: "16px" }}>
              Whether you’re managing a remote team or an in-office workforce,
              TimeTango’s advanced features and customizable options make it the
              ideal choice for effective employee management.
            </p>
            <p style={{ fontSize: "16px" }}>
              Choose TimeTango for a more efficient, engaged, and accountable
              workforce.
            </p>
          </div>
          <div className="cta mt-4 text-center">
            <Link to="/contact-us" className="cta-btn">
              Get in touch
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default ActivityTracking;
