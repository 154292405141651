import React from "react";
import { Link } from "react-router-dom";
import "../common.css";
const CallToActionSection = () => {
  return (
    <section className="section-padding text-center bg-gradient">
      <div className="container">
        <div className="col-md-8 col-lg-8 mx-auto">
          <div className="row paddingx">
            <h5 className="text-dark">
              Ready to simplify your employee attendance & time management
              system? Get in touch with our team to learn more about how
              TimeTango can transform your HR processes.
            </h5>
            <div className="cta mt-4">
              <Link to="/contact-us" className="cta-btn">
                Get in touch
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CallToActionSection;
