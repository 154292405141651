import "./common.css";
import { Link, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import CallToActionSection from "./commonComponent/CallToActionSection";
import { getPricingPlan } from "app/services/Services";
import { generateErrorMessage } from "app/utils/GenerateMessage";

const Pricing = () => {
  const navigate = useNavigate();
  const [pricingPlan, setPricingPlan] = useState([]);
  const [pricingPlanId, setPricingPlanId] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const structuredData = {
    "@context": "https://schema.org",
    "@type": "Product",
    name: "Timetango Pricing | Best Payroll & Attendance Management Tool",
    image: "https://timetango.in/assets/img/hero/hero_combine.svg",
    description:
      "Explore Timetango’s pricing plans for the best HRMS software in India. Affordable solutions for time tracking, attendance, and workforce management.",
    brand: {
      "@type": "Brand",
      name: "Time Tango",
      logo: "https://timetango.in/assets/logo3.svg",
      sameAs: [
        "https://www.facebook.com/people/TimeTango/61550584126267/",
        "https://www.linkedin.com/showcase/99884387/",
        "https://www.instagram.com/timetango.in/",
      ],
    },
    offers: {
      "@type": "Offer",
      url: "https://timetango.in",
      priceCurrency: "INR",
      price: "30",
    },
  };

  const getAllPricingPLans = () => {
    getPricingPlan()
      .then((res) => {
        if (res.status === 200) {
          setPricingPlan(res?.data?.data);
        }
      })
      .catch((error) => {
        generateErrorMessage(error?.response?.data?.message || "");
      });
  };

  useEffect(() => {
    getAllPricingPLans();
  }, []);

  const handlePlanId = (planId) => {
    navigate("/company-register", { state: { planId } }); // Pass the message using state
  };


  return (
    <>
      <Helmet>
        <title>
        Timetango Pricing | Best Payroll & Attendance Management Tool
        </title>
        <meta
          name="description"
          content="Explore Timetango’s pricing plans for the best HRMS software in India. Affordable solutions for time tracking, attendance, and workforce management."
        />
        <meta
          property="og:title"
          content="Timetango Pricing | Best Payroll & Attendance Management Tool"
        />
        <meta
          property="og:description"
          content="Explore Timetango’s pricing plans for the best HRMS software in India. Affordable solutions for time tracking, attendance, and workforce management."
        />
        <meta
          property="og:image"
          content="https://timetango.in/assets/logo3.svg"
        />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://timetango.in/" />
        <link rel="canonical" href="https://timetango.in/" />
        <meta
          property="og:site_name"
          content="Timetango"
        />
        <meta name="language" content="english" />
        <meta
          name="google-site-verification"
          content="HeqJ8arMIZIDpCmRH1zlrNu601VS7Wp92JoTy_9bARM"
        ></meta>
        <script type="application/ld+json">
          {JSON.stringify(structuredData)}
        </script>
      </Helmet>
      {/* Component 1 */}
      <section className="section-padding feature_hero bg-gradient">
        <div className="container">
          <div className="row paddingx mt-70">
            <div className="col-md-10 col-lg-10 col-xs-12 col-sm-12  pt-md-0 text-center mx-auto">
              <h1 className="fs-2 fs-md-3 fs-sm-4">Our Amazing Prices!</h1>
              <p className="fs-16">
                At TimeTango, we believe in making employee Attendance tracking
                accessible to all businesses. That's why we offer a free 1-day
                trial for you to experience the full potential of our intuitive
                employee Attendance management app. No obligations, just a
                chance to see how TimeTango can transform your HR processes.
              </p>
              <p className="fs-16">
                After your trial period, you can choose from our affordable
                pricing plans, tailored to meet the needs of organizations of
                all sizes. Whether you're a small startup or a large enterprise,
                we have a plan that fits your workforce and budget.
              </p>
            </div>
            {/* <div className="col-md-6 col-lg-6 col-xs-12 col-sm-12 ">
              <img
                src="assets/img/feature/front-hero.png"
                alt="mobile attendance App "
              />
            </div> */}
          </div>
        </div>
      </section>
      {/* component 2*/}
      <section className="price-table text-center section-padding">
        <div className="container">
          <div className="row new-px price_item_wrapper justify-content-md-center row paddingx">
            {/* <div className="col-12 col-md-3 col-lg-3 mx-md-3 my-md-3 price-item my-2 card paddingy">
              <div className="price_head">
                <h4 className="fw-bold fs-3">Medium</h4>
              </div>
              <div className="price_spec fs-18">
                <p>
                  Users: <span>51-100</span>
                </p>
                <p>&#8377; 40</p>
                <p>Per user per month</p>
              </div>
              <div className="price_btn">
                <Link to="/company-register">Get Started</Link>
              </div>
            </div>
            <div className=" col-12 col-md-3 col-lg-3 mx-md-3 my-md-3 price-item my-2 card paddingy">
              <div className="price_head">
                <h4 className="fw-bold fs-3">Enterprise</h4>
              </div>
              <div className="price_spec fs-18">
                <p>
                  Users: <span>100+</span>
                </p>
                <p>&#8377; 30</p>
                <p>Per user per month</p>
              </div>
              <div className="price_btn">
                <Link to="/company-register">Get Started</Link>
              </div>
            </div> */}
            {pricingPlan?.map((item, index) => (
              <div
                className="col-12 col-md-3 col-lg-3 mx-md-3 my-md-3 price-item my-2 card paddingy"
                key={index}
              >
                <div className="price_head">
                  <h4 className="fw-bold fs-3">{item?.planName}</h4>
                </div>
                <div className="price_spec fs-18">
                  <p>
                    Users:{" "}
                    <span>
                      {item?.userMinCount}-{item?.userMaxCount}
                    </span>
                  </p>
                  <p>&#8377; {item?.monthlyPricePerUser}</p>
                  <p>Per user per month</p>
                </div>
                <div
                  className="price_btn"
                  onClick={() => handlePlanId(item?.planId)}
                >
                  <Link>Get Started</Link>
                </div>
              </div>
            ))}

            <div className="col-md-12 col-lg-12 col-xs-12 col-sm-12 ">
              <div className="top_title p-4 mx-auto">
                <h2 className="fs-4">
                  Upgrade to include Activity Tracking for ₹ 100 per license
                </h2>
              </div>
            </div>
            <div className="col-md-12 col-lg-12 col-xs-12 col-sm-12">
              <div className="pt-4">
                <Link to="/company-register" className="txt_btn">
                  Try it for Free
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Component 3 */}
      <div className="bg-gradient component-padding">
        <div className="container">
          <div className=" col-10 text-center mx-auto mb-4">
            <p style={{ fontSize: "16px" }}>
              Join hundreds of businesses worldwide that have already
              streamlined their workforce management. Make HR a breeze with our
              smart, easy-to-use platform.
            </p>
            <p style={{ fontSize: "16px" }}>
              Ready to get started? Try TimeTango for free and discover the
              difference!
            </p>
          </div>
          <div className="cta mt-4 text-center">
            <Link to="/contact-us" className="cta-btn">
              Get in touch
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};
export default Pricing;
