// import "./layout.css";
import { Link } from "react-router-dom";
import FacebookOutlinedIcon from "@mui/icons-material/FacebookOutlined";

const Footer = () => {
  return (
    <>
      <section id="footer">
        <div className="container ">
          <div className="row">
            <div className="col-lg-4 col-md-4">
              <div className="footer-logo">
                <a className="f-logo" href="#home">
                  <img
                    className="footerlogoCls"
                    src="/assets/logo3.svg"
                    alt="Time Tango"
                  />
                </a>
                <p style={{ fontSize: "14px" }}>
                  Embrace the future of office check-in and checkout with
                  TimeTango, the cutting-edge mobile app that revolutionizes the
                  way you manage employee attendance.
                </p>

                <div className="location links ">
                  <ul className="d-flex social_link_wrapper">
                    <li>
                      <a
                        href="https://www.facebook.com/profile.php?id=61550584126267"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img src="assets/icons/social/fb.png" alt="fb" />
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.linkedin.com/showcase/99884387/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img
                          src="assets/icons/social/linkedin.png"
                          alt="linkedin"
                        />
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.instagram.com/timetango.in/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img
                          src="assets/icons/social/instagram.png"
                          alt="instagram"
                        />
                      </a>
                    </li>
                  </ul>
                </div>
                {/* <br /> */}
              </div>
            </div>
            <div className="col-lg-1 col-sm-12 col-md-4 ps-0 ps-lg-4 ">
              <div className="links ms-o ms-md-5 ms-lg-0 ">
                <h3>Links</h3>
                <ul style={{ padding: 0, margin: 0 }}>
                  <li style={{ marginBottom: "1px" }}>
                    <Link
                      className="nav-link footernavlinks hover1 fs-6"
                      to="/"
                      style={{ fontSize: "14px" }}
                    >
                      Home
                    </Link>
                  </li>

                  <li style={{ marginBottom: "1px" }}>
                    <Link
                      className="nav-link footernavlinks hover1"
                      to="/pricing"
                      style={{ fontSize: "14px" }}
                    >
                      Pricing
                    </Link>
                  </li>
                  {/* <li style={{ marginBottom: "1px" }}>
                    <Link
                      className="nav-link footernavlinks hover1"
                      to="/blogs"
                      style={{ fontSize: "14px" }}
                    >
                      Blog's
                    </Link>
                  </li> */}
                  {/* <li style={{ marginBottom: "1px" }}>
                    <Link
                      className="nav-link footernavlinks hover1"
                      to="/FAQ"
                      style={{ fontSize: "14px" }}
                    >
                      FAQ's
                    </Link>
                  </li> */}
                  <li style={{ marginBottom: "1px" }}>
                    <Link
                      to={"/logIn"}
                      className="link hover1"
                      style={{ fontSize: "14px" }}
                    >
                      Login
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-3 col-sm-12 col-md-4 ps-0 ps-lg-4">
              <div className="links ms-0 ms-lg-4">
                <h3> Features</h3>
                <ul style={{ padding: 0, margin: 0 }}>
                  <li style={{ marginBottom: "1px" }}>
                    <Link
                      className="nav-link footernavlinks hover1"
                      style={{ fontSize: "14px" }}
                      to="/wfh-support"
                    >
                      WFH Support
                    </Link>
                  </li>
                  <li style={{ marginBottom: "1px" }}>
                    <Link
                      className="nav-link footernavlinks hover1"
                      style={{ fontSize: "14px" }}
                      to="/real-time-insight"
                    >
                      Real-time Insight
                    </Link>
                  </li>
                  <li style={{ marginBottom: "1px" }}>
                    <Link
                      className="nav-link footernavlinks hover1"
                      style={{ fontSize: "14px" }}
                      to="/activity-tracking"
                    >
                      Activity Tracking
                    </Link>
                  </li>
                  <li style={{ marginBottom: "1px" }}>
                    <Link
                      to="/leave-mangement"
                      className="nav-link footernavlinks hover1"
                      style={{ fontSize: "14px" }}
                    >
                      Leave Management
                    </Link>
                  </li>
                  <li style={{ marginBottom: "1px" }}>
                    <Link
                      className="nav-link footernavlinks hover1"
                      style={{ fontSize: "14px" }}
                      to="/compliences-report"
                    >
                      Detailed Compliance Reports
                    </Link>
                  </li>
                  <li style={{ marginBottom: "1px" }}>
                    <Link
                      className="nav-link footernavlinks hover1"
                      style={{ fontSize: "14px" }}
                      to="/visitor-manegement"
                    >
                      Visitors Management
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-4 col-sm-12 col-md-4 ps-0 ps-lg-4">
              <div className="location links">
                <h3>Contact</h3>
                <p className="noTopPadding">
                  <a
                    href="https://maps.app.goo.gl/uhDQhGPL7yDBuBbS9"
                    target="_blank"
                    className="hover1  text-capitalize"
                    rel="noreferrer"
                  >
                    Office 201, Mont Vert Zenith, Baner Rd, Riviresa Society,
                    Baner, Pune, Maharashtra 411045
                  </a>
                </p>
                <p className="">
                  <a href="tel:+919130081345" className="hover1">
                    <i className="fa fa-phone"></i> +91 9130081345
                  </a>
                </p>
                {/* <h3>Email</h3> */}
                <p className="">
                  <a href="mailto:sales@gunadhyasoft.com" className="hover1">
                    <i className="fa fa-envelope"></i> sales@gunadhyasoft.com
                  </a>
                </p>
              </div>
            </div>
          </div>
          <hr className="my-0" />
          <div className="row align-items-center justify-content-between">
            <div className="col-lg-12 col-md-12 moja-loss pt-0 pt-md-3 order-2 order-md-0">
              <div className="fop-btm">
                <h2 className="copy_right">
                  <span>
                    Product by{"  "}
                    <a
                      href="https://www.gunadhyasoft.com/"
                      target="_blank"
                      rel="noreferrer"
                      className="hover1 text-color"
                    >
                      Gunadhya Software Solutions Pvt&nbsp;Ltd
                    </a>
                  </span>
                </h2>
              </div>
            </div>
          </div>
        </div>
      </section>
      {
        <>
          <script src="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/js/bootstrap.bundle.min.js"></script>
        </>
      }
    </>
  );
};

export default Footer;
