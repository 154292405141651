import "./common.css";
import Aos from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";
import { Helmet } from "react-helmet";
import CallToActionSection from "./commonComponent/CallToActionSection";
const RealTimeInsights = () => {
  useEffect(() => {
    Aos.init({
      duration: 1000,
      once: true,
    });
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const structuredData = {
    "@context": "https://schema.org",
    "@type": "Product",
    name: "Real-Time Employee Attendance Tracking & Insights | Timetango App",
    image: "https://timetango.in/assets/img/hero/hero_combine.svg",
    description:
      "Get real-time insights into employee attendance and productivity with Timetango. A top-notch time tracking and productivity software in India.",
    brand: {
      "@type": "Brand",
      name: "Time Tango",
      logo: "https://timetango.in/assets/logo3.svg",
      sameAs: [
        "https://www.facebook.com/people/TimeTango/61550584126267/",
        "https://www.linkedin.com/showcase/99884387/",
        "https://www.instagram.com/timetango.in/",
      ],
    },
    offers: {
      "@type": "Offer",
      url: "https://timetango.in",
      priceCurrency: "INR",
      price: "30",
    },
  };
  return (
    <>
      <Helmet>
        <title>
        Real-Time Employee Attendance Tracking & Insights | Timetango App
        </title>
        <meta
          name="description"
          content="Get real-time insights into employee attendance and productivity with Timetango. A top-notch time tracking and productivity software in India."
        />
        <meta
          property="og:title"
          content="Real-Time Employee Attendance Tracking & Insights | Timetango App"
        />
        <meta
          property="og:description"
          content="Get real-time insights into employee attendance and productivity with Timetango. A top-notch time tracking and productivity software in India."
        />
        <meta
          property="og:image"
          content="https://timetango.in/assets/logo3.svg"
        />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://timetango.in/" />
        <link rel="canonical" href="https://timetango.in/" />
        <meta
          property="og:site_name"
          content="Timetango"
        />
        <meta name="language" content="english" />
        <meta
          name="google-site-verification"
          content="HeqJ8arMIZIDpCmRH1zlrNu601VS7Wp92JoTy_9bARM"
        ></meta>
        <script type="application/ld+json">
          {JSON.stringify(structuredData)}
        </script>
      </Helmet>
      {/* Component 1 */}
      <section className="section-padding feature_hero">
        <div className="container">
          <div className="d-flex flex-column flex-md-row gap-4 align-items-center paddingx mt-70">
            <div className="col-md-6 col-lg-6 col-xs-12 col-sm-12  pt-md-0">
              <h1 className="fs-1 fs-md-3 fs-sm-4">
                Employee Real-Time Insight
              </h1>
              <p className="fs-16">
                The &quot;Employee Real-time Insight&quot; feature, provides
                businesses with an effective tool for real-time monitoring of
                employee activities allowing for seamless tracking and
                management.
              </p>
              <p className="fs-16">
                With its intuitive design and high functionality, TimeTango
                enhances the traditional approach to time management, and
                empowers businesses to optimize productivity like never before.
              </p>
            </div>
            <div
              className="col-md-6 col-lg-6 col-xs-12 col-sm-12 "
              data-aos="fade-up-right"
            >
              <img
                src="assets/img/real_time_insights.svg"
                alt="Employee Real-Time Insight"
                className="mx-auto w-100 h-100"
              />
            </div>
          </div>
        </div>
      </section>
      {/* Component 2 */}
      <section className="component-padding bg-white">
        <div className="container">
          <div className="row align-items-center mb-4 paddingx">
            <div className="col-md-7 col-lg-7 col-xs-12 col-sm-12">
              <h2 className="fs-2">Real-Time Notifications to Admins</h2>
              <ul className="comp2para fs-16">
                <li>
                  TimeTango Real-time Employee Insight feature is built to
                  deliver real-time updates to admins. offering admins an
                  immediate overview of employee&#39;s work hours and
                  attendance.
                </li>
                <li>
                  This feature not only makes time-tracking simpler, but also
                  improves accountability across the team.
                </li>
              </ul>
            </div>
            <div className="col-md-5 col-lg-5 col-xs-12 col-sm-12">
              <img
                src="assets/img/real_time_insight/real_time_notifications_to_admins.svg"
                alt="real time notifications to admins"
                className="mx-auto featureImg"
              />
            </div>
          </div>
          <div className="row align-items-center mb-4 paddingx">
            <div className="col-md-7 col-lg-7 col-xs-12 col-sm-12 mt-4 mt-md-0 order-md-2">
              <h2 className="fs-2 ">
                Admins receive automatic notifications for
              </h2>
              <ul className="comp2para fs-16">
                <li>
                  Check-in and Check-out: Each time an employee clocks in or
                  out, the admin is alerted, providing instant visibility into
                  attendance patterns.
                </li>
                <li>
                  Working Hours: Real-time insights show exactly how many hours
                  employees have worked, helping managers ensure that work hours
                  align with organizational policies.
                </li>
                <li>
                  Location Tracking: Especially useful for remote or field-based
                  employees, this feature ensures that the check-ins are
                  recorded from verified locations, reducing discrepancies in
                  time reports.
                </li>
              </ul>
            </div>
            <div className="col-md-5 col-lg-5 col-xs-12 col-sm-12 order-md-1">
              <img
                src="assets/img/real_time_insight/admins_receive_automatic_notifications_for.svg"
                alt="admins receive automatic notifications"
                className="mx-auto featureImg"
              />
            </div>
          </div>
        </div>
      </section>
      {/* Component 3 */}
      <section className="component-padding bg-smooth">
        <div className="container px-md-0">
          <div className="row paddingx">
            <h2 className="text-dark text-center">
              Benefits of Real-time Notifications
            </h2>
          </div>
          <div className="row justify-content-evenly paddingx">
            <div className="col-lg-3 col-md-6 col-sm-6">
              <div className="text-center">
                <div className="feature_img">
                  <img
                    src="assets/icons/seamless.svg"
                    alt="Boosts Transparency Real-time insights"
                  />
                </div>
                <h3 className="fs-5">Boosts Transparency</h3>
                <p className="text-center fs-16">
                  Real-time insights provide transparency in tracking, removing
                  the need for constant follow-ups.
                </p>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-6">
              <div className="text-center">
                <div className="feature_img">
                  <img
                    src="assets/icons/seamless.svg"
                    alt="Seamlessly Convenient"
                  />
                </div>
                <h3 className="fs-5">Minimizes Administrative Load</h3>
                <p className="text-center fs-16">
                  Automated notifications reduce the time spent on manual
                  tracking, allowing HR teams to focus on more strategic tasks.
                </p>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6 col-md-6 ">
              <div className="text-center">
                <div className="feature_img ">
                  <img
                    src="assets/icons/image_auth.svg"
                    alt="Advanced Image Authentication"
                  />
                </div>
                <h3 className="fs-5">Promotes Punctuality</h3>
                <p className="text-center fs-16">
                  Employees become more mindful of their attendance and working
                  hours, enhancing punctuality and productivity.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Component 4 */}
      <section className="section-padding bg-white">
        <div className="container paddingx">
          <div className="row paddingx">
            <div className="col-md-12 col-lg-12 col-xs-12 col-sm-12 text-center">
              <h2 className="fs-2 fs-md-3 fs-sm-4">
                Why Choose TimeTango’s Real-Time Insight Feature?
              </h2>
              <p className="fs-16">
                Employee Real-time Insight feature, provides an unparalleled
                approach to employee time management. By offering real-time
                insights, efficient timesheet management, location tracking and
                advanced analytics, TimeTango transforms how businesses manage
                their workforce.
              </p>
              <p className="fs-16 mb-2">
                This feature not only saves time but also improves productivity.
                making it an essential tool for any organization striving to
                optimize its operations.
              </p>
            </div>
          </div>
          <div className="tool mb-4 paddingx">
            <h3 className="text-dark fs-4">Attendance Trends</h3>
            <ul>
              <li className="fs-16">
                Identify patterns in absenteeism, overtime, and tardiness,
                helping managers address issues proactively.
              </li>
            </ul>
          </div>
          <div className="tool mb-4 paddingx">
            <h3 className="text-dark fs-4">Productivity Metrics</h3>
            <ul>
              <li className="fs-16">
                Gain insight into the amount of time employees spend on tasks,
                facilitating better workload distribution.
              </li>
            </ul>
          </div>
          <div className="tool mb-4 paddingx">
            <h3 className="text-dark fs-4">Data-Driven Decisions</h3>
            <ul>
              <li className="fs-16">
                With detailed reports on attendance and performance, businesses
                can make informed decisions to boost productivity.
              </li>
            </ul>
          </div>
          <div className="tool mb-4 paddingx">
            <p className="fs-16">
              Whether you’re managing a remote workforce, tracking
              time-sensitive projects or simply looking for a more efficient way
              to handle employee attendance. TimeTango’s employee time
              management software offers a solution tailored to your needs.
            </p>
            <p className="fs-16">
              Managing your teams time is made easier and more intelligent, with
              Time Tango.
            </p>
            <p className="fs-16">
              Experience the benefits of real-time insight and a happier, more
              accountable workforce.
            </p>
          </div>
        </div>
      </section>
      {/* Component 5*/}

      <CallToActionSection></CallToActionSection>
    </>
  );
};

export default RealTimeInsights;
