export const LOGIN_URL="systems/user/login"
export const ADMIN_LOGIN_URL="/administrative/login"
export const FORGOT_PASSWORD_URL="/systems/user-forget-password-request"
export const ADMIN_FORGOT_PASSWORD_URL="/administrative/admin-forget-password-request"
export const VALIDATE_OTP="/systems/user-validate-password"
export const ADMIN_VALIDATE_OTP="/administrative/admin-validate-otp"
export const RESET_PASSWORD="/systems/user-reset-password"
export const ADMIN_RESET_PASSWORD="/administrative/admin-reset-password"
export const CHECK_IN="/attendence/user-attendance-checkIn"
export const CHECK_OUT="/attendence/user-attendance-checkout"
export const EMPLOYEE_PROFILE=""
export const CHEACK_IN_OUT_HISTORY="/attendence/users-attendance-list"
export const ADMIN_CHEACK_IN_OUT_HISTORY="/systems/attendance/current-month-employee-attendance-list"
export const EMPLOYEE_LIST="/dashboard/get-all-employee-list"
export const HOLIDAY_CALENDER_LIST="/systems/holiday/get-all-holiday-calender"
export const VISITORLIST="/attendence/visitor/admin-visitors-list"
export const GET_EXPORT_EMPLOYEE_LIST="/dashboard/export-get-all-employee-list"
export const EMPLOYEE_DETAIL="/dashboard/get-employee-by-id/"
export const EDIT_EMPLOYEE_DETAIL="/systems/employee/update-employee-admin/"
// export const EDIT_EMPLOYEE_DETAIL="/dashboard/update-employee_admin/"
// export const EDIT_EMPLOYEE_DETAIL="dashboard/update-employee-admin/"
export const DELETE_EMPLOYEE="dashboard/delete-employee/"
export const DELETE_HOLIDAY="/systems/holiday/delete-holiday-record"
export const GET_HOLIDAY_ROW_DATA="/systems/holiday/get-holiday-calender"
export const UPDATE_HOLIDAY_RECORD="/systems/holiday/update-holiday-record"
export const ADD_EMPLOYEE_DETAIL="/systems/register-new-user"
export const BULK_ADD_EMPLOYEE_DETAIL="/systems/bulk-user-register"
export const BULK_ADD_HOLIDAY="/systems/holiday/bulk-insert"
export const GET_ALL_ROLES="/systems/role/all-roles"
export const GET_ALL_DEPARTMENT="/systems/department/all-department"
export const GET_ALL_DESIGNATION="/systems/designation/all-designation"
export const GET_ALL_RESOURCES="/systems/resource/all-resource"
export const GET_ALL_ACTIONS="systems/action/all-actions"
export const ADD_NEW_ROLE="/systems/role/add-role"
export const GET_ALL_ROLESLIST="/systems/role/all-roles"
export const DELETE_Role="/systems/role/delete-role/"
export const UPDATE_ROLE="/systems/role/update-role/"
export const COMPANYACTION="/administrative/company/action-against-company"
// export const SIGN_IN_USER_RESET_PASSWORD="/systems/sign-in-user-reset-password"
export const SIGN_IN_USER_RESET_PASSWORD="/dashboard/sign-in-user-reset-password"
export const UPDATE_USER="/dashboard/update-employee"
export const GET_SINGLE_EMPLOYEE_DETAILS="/dashboard/get-employee-data"
export const GET_TODAY_EMPLOYEES_ATTENDACE_LIST="/systems/attendance/current-employee-attendance-list"
export const RESET_EMPLOYEE_ATTENDENCE="/systems/attendance/admin-side-checkinout-reset"
export const DEVICE_AUTH_REQUEST_LIST="/desktop/deviceauthrequests"
export const DEVICE_AUTH_REQUEST_STATUS_CHANGE="/desktop/deviceauthrequeststatuschange"
export const GET_ALL_COMPANY_CONFIG="/systems/attendance/admin-get-company-configuration"
export const EDIT_COMPANY_CONFIG="/systems/attendance/admin-update-company-configuration"
export const GET_CONFIG="/attendence/get-company-configuration-web"
export const GET_COMPLIENCE_REPORT="/systems/attendance/attendance-complaince-report"

export const GET_FILTRED_EMPLOYEE_LIST="/systems/attendance/filter-report"
export const GET_PROJECT_LABEL="/attendence/project-label/get-all-project-labels"
export const GET_PROJECT_TIMESHEET="/attendence/project-label/get-time-sheet-report"
export const CHECK_IN_ABCENT_EMPLOYEE_LIST="/attendence/absent-employee-list"
export const Add_EMPLOYEE_CHECKIN="/attendence/add-absent-employee-attendance"
export const EMPLOYEE_REQUEST_CHECKIN="/attendence/requests/add-attendance-request"
export const CONTACT_US="/systems/contact-us"

export const COMPANYREGISTER="/front/company-register"
// export const COMPANYREGISTER="/administrative/company/company-form-details"
export const COMAPANY_LIST="/administrative/company/get-all-companies"
export const COMAPANY_DETAILS="/administrative/company/get-company-details/"
export const UPDATE_COMAPANY_DETAILS="/administrative/company/update-company-details/"
export const ADD_COMPANY_REQUEST_FORM="/administrative/company/register-company"

export const GET_DASHBOARD_EMPLOYEE_STATUS="/dashboard/admin/get-dashboard-count-status"
export const PRESENT_ABSENT_LIST="dashboard/admin/get-dashboard-present-absent-list"
export const PRESENT_ABSENT_COUNT="dashboard/admin/get-dashboard-present-absent-count"
export const YEARLY_AVG="/dashboard/admin/get-dashboard-yearly-average"
export const MONTHLY_AVG="/dashboard/admin/get-dashboard-monthly-average"
export const DAILY_AVG="/dashboard/admin/get-dashboard-daily-average"
export const ACHIVERS_LIST="/dashboard/admin/get-dashboard-achivers"
export const GET_LEAVE_REPORT="/leave/employe-leave-list"
export const GET_EMPLOYEE_LEAVE_REPORT="/leave/employe-leave-list-details"
export const GET_EMPLOYEE_REQUEST_LIST="/attendence/requests/employee-get-all-requests" 
export const GET_ADMIN_EMPLOYEE_REQUEST_LIST="/attendence/requests/get-all-requests" 
export const EMPLOYEE_CHECKIN_OUT_CANCEL_REQUEST="/attendence/requests/delete-attendance-request" 
export const ADMIN_CHECKIN_OUT_CANCEL_REQUEST="/attendence/requests/admin-update-attendance-request" 
export const GET_TODAY_ATTENDACE_REPORT="/attendence/get-attendance-by-login-user"
export const GET_EDIT_REQUEST_CHECK_INOUT="/attendence/requests/get-request"
export const UPDATE_EDIT_CHECKIN_OUT_REQUEST_DATA="/attendence/requests/update-attendance-request"
export const UPDATE_LEAVE_REPORT_STATUS="/leave/add-employe-leave-exception"
// export const UPDATE_LEAVE_REPORT_STATUS="/leave/employe-leave-approve"
export const GENERATE_LEAVE_MANUAL="/systems/attendance/add-leave-record"
export const GET_EPLOYEE_LEAVE_LIST="/dashboard/employee-leaves"

export const LEAVE_CATEGORY_TYPE="/leavemanagementcommon/dropdown-leave-category-type"
export const ACCRUALPERIOD="/leavemanagementcommon/dropdown-accrual-period-type"
export const ACCRUAL_TIME="/leavemanagementcommon/dropdown-accrual-time-type"
export const ACCRUAL_RESET="/leavemanagementcommon/dropdown-accrual-reset"
export const ADD_LEAVE_TYPE="/leavetype/add-leave-type"
export const LEAVE_LIST="/leavetype/get-leave-type-list"
export const UPDATE_LEAVE_TYPE="/leavetype/update-leave-type"
export const GET_ALL_LEAVE_CONFIG="/leavemanagementsetting/get-leave-management-config"
export const GET_ALL_LEAVE_CYCLE_DATA="/leavemanagementcommon/dropdown-leave-cycle"
export const UPDATE_LEAVE_CONFIG="/leavemanagementsetting/update-leave-management-config"
export const IMPORT_LEAVE_ADJUSTMENT_LIST="/leaveadjustment/get-export-leave-adjustment-employee-list"
export const LEAVE_ADJUSTMENT_BULK_UPLOAD="/leaveadjustment/import-employee-leave-adjustment"
export const EMPLOYEE_LEAVE_ADJUSTMENT_LIST="/leaveadjustment/get-leave-adjustment-employee-list"
export const UPDATE_EMPLOYEE_LEAVE_ADJUSTMENT_LIST="/leaveadjustment/update-employee-leave-adjustment"
export const LEAVE_TYPE="/leavemanagementcommon/dropdown-leave-type"
export const EMPLOYEE_MONTHLY_ACHIVERS="/dashboard/admin/get-employee-dashboard-achivers"


// employee activity apis end points
export const GET_EMPLOYEE_ACTIVITY_LIST="/activitytracker/employee-activity-list"
export const GET_EMPLOYEE_ACTIVITY_IMAGES_LIST="/activitytracker/employee-activity-screenshot-list"
export const GET_EMPLOYEE_ACTIVITY_APP_LIST="/activitytracker/activity-review-app-list"
export const CHANGE_APP_REVIEW_STATUS="/activitytracker/review-app-status-change"
export const ACTIVITY_EMPLOYEE_LIST="/activitytracker/dropdown-employee-list-activity"
export const ACTIVITY_EMPLOYEE_APP_DURATIONS="/activitytracker/employee-activity-app-durations"


// device Approval
export const EMPLOYEE_DEVICE_AUTH_REVOKE="/desktop/deviceauthrevoke"
export const APP_HEARTBEAT_EMPLOYEE_LIST="/systems/attendance/appheartbeatlist/desktop"
export const GET_USER_ACTIVITY_TRACKKER_CONFIG="/activitytrackermanagementsetting/get-activity-tracker-management-config"
export const PUT_USER_ACTIVITY_TRACKKER_CONFIG="/activitytrackermanagementsetting/update-activity-tracker-management-config"


// apk vertsion Apis
export const GET_APP_VERSION_AND_PATH="/application/versionlist/mobile"
export const GET_DESKTOP_APP_VERSION_AND_PATH="/application/versionlist/desktop"
export const GET_PRICING_PLANS="/front/plan-list"


//company plan
export const GET_COMPANY_PLAN="/administrative/company/get-company-plan-details"
export const UPDATE_COMAPANY_PLAN_DETAILS="/administrative/company/update-company-plan-details"
export const GET_VERIFY_EMAIL="/front/verify-email"


