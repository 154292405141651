import "./common.css";
import Aos from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";
import { Helmet } from "react-helmet";
import CallToActionSection from "./commonComponent/CallToActionSection";
const LeaveMangement = () => {
  useEffect(() => {
    Aos.init({
      duration: 1000,
      once: true,
    });
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const structuredData = {
    "@context": "https://schema.org",
    "@type": "Product",
    name: "Employee Leave Management Software | Best HRMS Tool in India",
    image: "https://timetango.in/assets/img/hero/hero_combine.svg",
    description:
      "Simplify leave requests and approvals with Timetango. An employee attendance management tool offering seamless leave management and tracking solutions.",
    brand: {
      "@type": "Brand",
      name: "Time Tango",
      logo: "https://timetango.in/assets/logo3.svg",
      sameAs: [
        "https://www.facebook.com/people/TimeTango/61550584126267/",
        "https://www.linkedin.com/showcase/99884387/",
        "https://www.instagram.com/timetango.in/",
      ],
    },
    offers: {
      "@type": "Offer",
      url: "https://timetango.in",
      priceCurrency: "INR",
      price: "30",
    },
  };

  return (
    <>
      <Helmet>
        <title>
        Employee Leave Management Software | Best HRMS Tool in India
        </title>
        <meta
          name="description"
          content="Simplify leave requests and approvals with Timetango. An employee attendance management tool offering seamless leave management and tracking solutions."
        />
        <meta
          property="og:title"
          content="Employee Leave Management Software | Best HRMS Tool in India"
        />
        <meta
          property="og:description"
          content="Simplify leave requests and approvals with Timetango. An employee attendance management tool offering seamless leave management and tracking solutions."
        />
        <meta
          property="og:image"
          content="https://timetango.in/assets/logo3.svg"
        />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://timetango.in/" />
        <link rel="canonical" href="https://timetango.in/" />
        <meta
          property="og:site_name"
          content="Timetango"
        />
        <meta name="language" content="english" />
        <meta
          name="google-site-verification"
          content="HeqJ8arMIZIDpCmRH1zlrNu601VS7Wp92JoTy_9bARM"
        ></meta>
        <script type="application/ld+json">
          {JSON.stringify(structuredData)}
        </script>
      </Helmet>
      {/* component 1 */}
      <section className="section-padding feature_hero">
        <div className="container">
          <div className="d-flex flex-column flex-md-row gap-4 align-items-center paddingx mt-70">
            {" "}
            <div className="col-md-6 col-lg-6 col-xs-12 col-sm-12  pt-md-0">
              <h1>Employee Leave Management</h1>
              <p className="fs-16">
                Simplifying Leave Management with Attendance-Based Tracking!
              </p>
              <p className="fs-16">
                TimeTango’s Employee Leave Management Feature is crafted to
                streamline the process, empowering employees, and managers to
                handle leave requests seamlessly.
              </p>
            </div>
            <div
              className="col-md-6 col-lg-6 col-xs-12 col-sm-12 "
              data-aos="fade-up-right"
            >
              <img
                src="assets/img/hero/Hero_Leave_managment.png"
                alt="Leave Management"
                className="mx-auto w-100 h-100"
              />
            </div>
          </div>
        </div>
      </section>

      {/* component 2 */}
      <section className="component-padding bg-white">
        <div className="container d-flex flex-column gap-4">
          <div className="row align-items-center mb-4 paddingx">
            <div className="col-md-7 col-lg-7 col-xs-12 col-sm-12">
              <h2 className="fs-2">Employee Attendance App</h2>
              <ul className="comp2para fs-16">
                <li>
                  Unified Platform: TimeTango’s employee attendance app brings
                  attendance and leave management onto one platform. allowing
                  for seamless tracking of hours worked, overtime and leaves.
                </li>
                <li>
                  Real-Time Attendance Tracking: With real-time tracking,
                  employees and managers can monitor attendance accurately.
                </li>
                <li>
                  Mobile Accessibility: Employees can mark their attendance and
                  apply for leave via their smartphones, offering flexibility
                  and accessibility. The mobile-friendly employee attendance app
                  means employees no longer have to rely on physical attendance
                  systems.
                </li>
              </ul>
            </div>
            <div className="col-md-5 col-lg-5 col-xs-12 col-sm-12 ">
              <img
                src="assets/img/leave_mangement/employee_attedence_app.svg"
                alt="Employee Attendance App"
                className="mx-auto featureImg"
              />
            </div>
          </div>
          <div className="row align-items-center mb-4 mt-4 mt-md-0 paddingx">
            <div className="col-md-7 col-lg-7 col-xs-12 col-sm-12 order-md-2">
              <h2 className="fs-2 ">Accuracy in Leave Tracking</h2>
              <ul className="comp2para fs-16">
                <li>
                  Automated Leave Calculation: The system automatically
                  calculates available leave days based on attendance data. This
                  automation reduces errors and provides real-time visibility
                  into available leave balances for both employees and managers.
                </li>
                <li>
                  Leave Based on Attendance Data: By integrating leave requests
                  with attendance records, TimeTango’s leave management system
                  ensures that only actual work hours contribute to leave
                  balances. This prevents misuse and ensures accountability in
                  leave tracking.
                </li>
                <li>
                  Historical Records for Compliance: The tool maintains
                  comprehensive records of attendance and leave for each
                  employee. These historical records can be vital for audits and
                  compliance, reducing risks of mismanagement or discrepancies.
                </li>
              </ul>
            </div>
            <div className="col-md-5 col-lg-5 col-xs-12 col-sm-12 order-md-1">
              <img
                src="assets/img/leave_mangement/accuracy_in_leave_tracking.svg"
                alt="Accuracy in Leave Tracking"
                className="mx-auto featureImg"
              />
            </div>
          </div>
          <div className="row align-items-center mb-4 mt-4 mt-md-0 paddingx">
            <div className="col-md-7 col-lg-7 col-xs-12 col-sm-12">
              <h2 className=" fs-2">
                {" "}
                Efficient Employee Leave Approval Workflows
              </h2>
              <ul className="comp2para fs-16">
                <li>
                  Clear Approvals and Notifications: The leave management tool
                  facilitates smooth communication between employees and
                  managers with automated notifications for leave requests,
                  approvals or rejections.
                </li>
                <li>
                  Customizable Approval Workflows: Managers can set up
                  multi-level approvals based on the organization’s hierarchy,
                  ensuring all leave requests go through the appropriate
                  channels. To minimizes delays and ensures swift responses.
                </li>
                <li>
                  Improved Employee Monitor Capabilities: Managers can monitor
                  employee attendance patterns and leave trend., helping
                  identify potential issues, such as frequent absences and
                  address them proactively.
                </li>
              </ul>
            </div>
            <div className="col-md-5 col-lg-5 col-xs-12 col-sm-12">
              <img
                src="assets/img/leave_mangement/efficient_employee_leave_approval_workflows.svg"
                alt="Efficient Employee Leave Approval Workflows"
                className="mx-auto featureImg"
              />
            </div>
          </div>
          <div className="row align-items-center mb-4 mt-4 mt-md-0 paddingx">
            <div className="col-md-7 col-lg-7 col-xs-12 col-sm-12 order-md-2">
              <h2 className="fs-2 ">Real-Time Analytics and Reports</h2>
              <ul className="comp2para fs-16">
                <li>
                  Comprehensive Analytics: TimeTango offers real-time insights
                  into attendance and leave trends. enabling HR teams to make
                  data- driven decisions.
                </li>
                <li>
                  Leave Balance Insights: With the leave management system, HR
                  teams can monitor leave balances across departments, allowing
                  for better planning and budgeting.
                </li>
                <li>
                  Employee-Specific Reports: Managers can generate reports on
                  individual employee attendance and leave history, offering a
                  clear picture of performance and patterns.
                </li>
              </ul>
            </div>
            <div className="col-md-5 col-lg-5 col-xs-12 col-sm-12 order-md-1">
              <img
                src="assets/img/leave_mangement/real_time_analytics_and_reports.svg"
                alt="Real-Time Analytics and Reports"
                className="mx-auto featureImg"
              />
            </div>
          </div>
          <div className="row align-items-center mb-4 mt-4 mt-md-0 paddingx">
            <div className="col-md-7 col-lg-7 col-xs-12 col-sm-12">
              <h2 className=" fs-2"> Cost Savings</h2>
              <ul className="comp2para fs-16">
                <li>
                  Minimized Absenteeism: By monitoring attendance and leave
                  closely, TimeTango helps reduce instances of unapproved
                  absences.
                </li>
                <li>
                  Reduced Administrative Workload: With an automated leave
                  management tool, HR teams can save time on manual
                  record-keeping and avoid redundant paperwork. This frees them
                  up to focus on strategic HR functions.
                </li>
                <li>
                  Better Planning for Team Projects: With accurate leave
                  tracking, managers can plan resources for projects and avoid
                  workflow disruptions. The system offers a clear view of who is
                  available and when, supporting efficient team scheduling.
                </li>
              </ul>
            </div>
            <div className="col-md-5 col-lg-5 col-xs-12 col-sm-12">
              <img
                src="assets/img/leave_mangement/cost_savings.svg"
                alt="Cost Savings"
                className="mx-auto featureImg"
              />
            </div>
          </div>
          <div className="row align-items-center mb-4 mt-4 mt-md-0 paddingx">
            <div className="col-md-7 col-lg-7 col-xs-12 col-sm-12 order-md-2">
              <h2 className="fs-2 ">Transparent Employee Engagement</h2>
              <ul className="comp2para fs-16">
                <li>
                  Clear Leave Policies: With transparency in leave policies and
                  real-time visibility of leave balances, employees feel more in
                  control and aware of their entitlements.
                </li>
                <li>
                  Improved Trust:An automated system for attendance and leave
                  builds trust among employees, as they know their records are
                  accurate.
                </li>
                <li>
                  Enhanced Flexibility: TimeTango employee attendance app allows
                  employees to access leave records anytime.
                </li>
              </ul>
            </div>
            <div className="col-md-5 col-lg-5 col-xs-12 col-sm-12 order-md-1">
              <img
                src="assets/img/leave_mangement/transparent_employee_engagement.svg"
                alt="Transparent Employee Engagement"
                className="mx-auto featureImg"
              />
            </div>
          </div>
        </div>
      </section>

      {/* Component 3 */}
      <section className="section-padding ">
        <div className="container paddingx">
          <div className="row mb-5 paddingx">
            <div className="col-md-12 col-lg-12 col-xs-12 col-sm-12 text-center">
              <h2 className="fs-2 fs-md-3 fs-sm-4">
                Why Choose TimeTango for Your Employee Leave Management Tool?
              </h2>
            </div>
          </div>

          <div className="tool mb-4 paddingx">
            <h3 className="text-dark fs-4">Unified Solution</h3>
            <ul>
              <li className="fs-16">
                TimeTango offers a solution, by combining tools for tracking
                attendance Managing leave in one platform to streamline
                workforce management processes effectively.
              </li>
            </ul>
          </div>

          <div className="tool mb-4 paddingx">
            <h3 className="text-dark fs-4">User Friendly Interface</h3>
            <ul>
              <li className="fs-16">
                Crafted for ease of use and straightforward navigation, by both
                employees and managers to reduce the time needed for learning.
              </li>
            </ul>
          </div>
          <div className="tool mb-4 paddingx">
            <h3 className="text-dark fs-4">Improved Compliance</h3>
            <ul>
              <li className="fs-16">
                TimeTango provides a reliable record-keeping system that helps
                organizations meet regulatory requirements and reduce the risk
                of disputes.
              </li>
            </ul>
          </div>
          <div className="tool mb-4 paddingx">
            <h3 className="text-dark fs-4"> Insightful Decision-Making</h3>
            <ul>
              <li className="fs-16">
                The tool provides valuable data to HR teams, supporting more
                effective planning and decision-making regarding workforce
                management
              </li>
            </ul>
          </div>
          <div className="tool mb-4 paddingx">
            <h3 className="text-dark fs-4"> Better tracking</h3>
            <ul>
              <li className="fs-16">
                Whether you’re looking for better tracking, employee monitoring,
                or detailed insights into attendance patterns. TimeTango is your
                ideal partner for seamless workforce management.
              </li>
            </ul>
          </div>
        </div>
      </section>

      {/* Component 4 */}
      <CallToActionSection></CallToActionSection>
    </>
  );
};

export default LeaveMangement;
