import "./common.css";
import { useEffect } from "react";
import { Helmet } from "react-helmet";
import CallToActionSection from "./commonComponent/CallToActionSection";

const DetailedComplianceReports = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const structuredData = {
    "@context": "https://schema.org",
    "@type": "Product",
    name: "Compliance Reports for Attendance & HRMS | Timetango App",
    image: "https://timetango.in/assets/img/hero/hero_combine.svg",
    description:
      "Generate error-free compliance reports with Timetango. A workforce time tracking and attendance management app for seamless HR operations.",
    brand: {
      "@type": "Brand",
      name: "Time Tango",
      logo: "https://timetango.in/assets/logo3.svg",
      sameAs: [
        "https://www.facebook.com/people/TimeTango/61550584126267/",
        "https://www.linkedin.com/showcase/99884387/",
        "https://www.instagram.com/timetango.in/",
      ],
    },
    offers: {
      "@type": "Offer",
      url: "https://timetango.in",
      priceCurrency: "INR",
      price: "30",
    },
  };
  return (
    <>
      <Helmet>
        <title>
        Compliance Reports for Attendance & HRMS | Timetango App
        </title>
        <meta
          name="description"
          content="Generate error-free compliance reports with Timetango. A workforce time tracking and attendance management app for seamless HR operations."
        />
        <meta
          property="og:title"
          content="Compliance Reports for Attendance & HRMS | Timetango App"
        />
        <meta
          property="og:description"
          content="Generate error-free compliance reports with Timetango. A workforce time tracking and attendance management app for seamless HR operations."
        />
        <meta
          property="og:image"
          content="https://timetango.in/assets/logo3.svg"
        />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://timetango.in/" />
        <link rel="canonical" href="https://timetango.in/" />
        <meta
          property="og:site_name"
          content="Timetango"
        />
        <meta name="language" content="english" />
        <meta
          name="google-site-verification"
          content="HeqJ8arMIZIDpCmRH1zlrNu601VS7Wp92JoTy_9bARM"
        ></meta>
        <script type="application/ld+json">
          {JSON.stringify(structuredData)}
        </script>
      </Helmet>
      {/* Component 1 */}

      <section className="section-padding feature_hero">
        <div className="container">
          <div className="d-flex flex-column flex-md-row gap-4 align-items-center paddingx mt-70">
            <div className="col-md-6 col-lg-6 col-xs-12 col-sm-12  pt-md-0">
              <h1>Detailed Compliance Reports</h1>
              <p className="fs-16">
                With the Detailed Compliance Reports feature, TimeTango empowers
                businesses to stay compliant effortlessly, making compliance
                tracking and management a seamless part of day-to-day
                operations.
              </p>
            </div>
            <div
              className="col-md-6 col-lg-6 col-xs-12 col-sm-12 "
              data-aos="fade-up-right"
            >
              <img
                src="assets/img/compliences_report/detailed_compliance_reports.svg"
                alt="detailed_compliance_reports"
                className="mx-auto w-100 h-100"
              />
            </div>
          </div>
        </div>
      </section>
      {/* Component 2 */}
      <section className="section-padding features_section bg-white">
        <div className="container px-md-0">
          <div className="row paddingx">
            <h2 className="text-center px-1">
              Key Benefits of the Detailed Compliance Reports
            </h2>
          </div>
          <div className="row features_wrapper paddingx">
            <div className="col-lg-4 col-md-6 col-sm-6">
              <div className="feature_item">
                <div className="feature_img">
                  <img
                    src="assets/img/compliences_report/tailored_report_generation.svg"
                    alt="Tailored Report Generation"
                  />
                </div>
                <h3>Tailored Report Generation</h3>
                <p className="fs-16 text-center">
                  TimeTango employee attendance app offers an array of
                  customizable reporting tools. enabling businesses to create
                  reports suited to their specific compliance needs.
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6 col-md-6 ">
              <div className="feature_item">
                <div className="feature_img ">
                  <img
                    src="assets/img/compliences_report/accurate_data_collection.svg"
                    alt="Accurate Data Collection"
                  />
                </div>
                <h3>Accurate Data Collection</h3>
                <p className="fs-16 text-center">
                  By capturing accurate clock-in and clock-out data, TimeTango
                  provides HRs with records that help avoid legal complications.
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6 col-md-6 ">
              <div className="feature_item">
                <div className="feature_img">
                  <img
                    src="assets/img/compliences_report/flexible_scheduling_for_reports.svg"
                    alt="Flexible Scheduling for Reports"
                  />
                </div>
                <h3>Flexible Scheduling for Reports</h3>
                <p className="fs-16 text-center">
                  Reports can be generated on a weekly, monthly, or customized
                  basis, allowing organizations to maintain up-to-date
                  compliance records without any manual effort.
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6 col-md-6">
              <div className="feature_item">
                <div className="feature_img">
                  <img
                    src="assets/img/compliences_report/in_depth_analysis.svg"
                    alt="In Depth Analysis"
                  />
                </div>
                <h3>In Depth Analysis</h3>
                <p className="fs-16 text-center">
                  The reporting function provides more, than data; it offers
                  detailed analyses of attendance trends and absenteeism
                  patterns as well, as overtime records to help HR teams stay
                  ahead of compliance issues proactively.
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6">
              <div className="feature_item">
                <div className="feature_img">
                  <img
                    src="assets/img/compliences_report/real-time_tracking.svg"
                    alt="Real-Time Tracking"
                  />
                </div>
                <h3>Real-Time Tracking</h3>
                <p className="fs-16 text-center">
                  With our employee time tracking app, provides managers with
                  real-time updates on attendance status. Alerts them to any
                  compliance issues for immediate action, by the HR team.
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6">
              <div className="feature_item">
                <div className="feature_img">
                  <img
                    src="assets/img/compliences_report/secure_data_handling.svg"
                    alt="Secure Data Handling"
                  />
                </div>
                <h3>Secure Data Handling</h3>
                <p className="fs-16 text-center">
                  TimeTango employee attendance management system prioritizes
                  the security of employee data. All attendance information is
                  stored with encrypted access controls, protecting sensitive
                  data from unauthorized access.
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6">
              <div className="feature_item">
                <div className="feature_img">
                  <img
                    src="assets/img/compliences_report/controlled_access.svg"
                    alt="Controlled Access"
                  />
                </div>
                <h3>Controlled Access</h3>
                <p className="fs-16 text-center">
                  Limit access to attendance and compliance data to authorized
                  personnel only. ensuring that data privacy is respected at
                  every level of the organization.
                </p>
              </div>
            </div>{" "}
            <div className="col-lg-4 col-sm-6">
              <div className="feature_item">
                <div className="feature_img">
                  <img
                    src="assets/img/compliences_report/centralized_compliance_management.svg"
                    alt="Centralized Compliance Management"
                  />
                </div>
                <h3>Centralized Compliance Management</h3>
                <p className="fs-16 text-center">
                  TimeTango offers a central platform for compliance management,
                  minimizing administrative overhead and improving accuracy.
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6">
              <div className="feature_item">
                <div className="feature_img">
                  <img
                    src="assets/img/compliences_report/geographically_adaptable.svg"
                    alt="Geographically Adaptable"
                  />
                </div>
                <h3>Geographically Adaptable</h3>
                <p className="fs-16 text-center">
                  Time Tangos compliance reporting function is flexible enough
                  to meet the compliance needs, in areas which&#39;s great for
                  businesses with operations in various locations. It takes into
                  consideration the labor laws to each region well, as local
                  holidays and reporting formats.
                </p>
              </div>
            </div>{" "}
            <div className="col-lg-4 col-sm-6">
              <div className="feature_item">
                <div className="feature_img">
                  <img
                    src="assets/img/compliences_report/visibility_for_remote_and_hybrid_teams.svg"
                    alt="Visibility for Remote and Hybrid Teams"
                  />
                </div>
                <h3>Visibility for Remote and Hybrid Teams</h3>
                <p className="fs-16 text-center">
                  Businesses dealing with hybrid work setups benefit from the
                  app by accessing compliance information tailored to locations
                  to ensure precise monitoring of employee activities regardless
                  of their work location.
                </p>
              </div>
            </div>{" "}
          </div>
        </div>
      </section>
      {/* Component 3 */}
      <section className="section-padding ">
        <div className="container">
          <div className="row paddingx">
            <div className="col-md-12 col-lg-12 col-xs-12 col-sm-12 text-center">
              <h2 className="fs-2 fs-md-3 fs-sm-4">
                Why Compliance Reporting Matters?
              </h2>
            </div>
          </div>

          <div className="tool mb-4 mt-4 paddingx">
            <h3 className="text-dark fs-4">Making Legal Compliance Easier</h3>
            <ul>
              <li className="fs-16">
                The TimeTango employee attendance monitoring app simplifies the
                process for businesses to meet obligations effortlessly. It's
                crucial to follow labor laws to avoid fines and legal troubles
                that could harm a company’s image.
              </li>
              <li className="fs-16">
                With the Detailed Compliance Reports tool your organization can
                ensure compliance without work involved.
              </li>
            </ul>
          </div>
          <div className="tool mb-4 paddingx">
            <h3 className="text-dark fs-4">
              Building Trust with Transparent Records
            </h3>
            <ul>
              <li className="fs-16">
                Ensuring compliant attendance records is crucial, for upholding
                the companys obligations while also promoting transparency and
                trust, among employees and stakeholders alike.
              </li>
              <li className="fs-16">
                With TimeTango’ powerful reporting feature, companies can stay
                ahead of compliance challenges, making it an essential tool for
                any modern HR team.
              </li>
            </ul>
          </div>
        </div>
      </section>

      {/* componnent 4 */}
      <CallToActionSection></CallToActionSection>
    </>
  );
};

export default DetailedComplianceReports;
