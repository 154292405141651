import {
  ADD_EMPLOYEE_DETAIL,
  ADD_NEW_ROLE,
  ADMIN_CHEACK_IN_OUT_HISTORY,
  DELETE_EMPLOYEE,
  DELETE_Role,
  EDIT_EMPLOYEE_DETAIL,
  EMPLOYEE_DETAIL,
  EMPLOYEE_LIST,
  FORGOT_PASSWORD_URL,
  ADMIN_VALIDATE_OTP,
  ADMIN_RESET_PASSWORD,
  ADMIN_FORGOT_PASSWORD_URL,
  GET_ALL_ACTIONS,
  GET_ALL_DEPARTMENT,
  GET_ALL_DESIGNATION,
  GET_ALL_RESOURCES,
  GET_ALL_ROLES,
  GET_ALL_ROLESLIST,
  GET_SINGLE_EMPLOYEE_DETAILS,
  LOGIN_URL,
  RESET_PASSWORD,
  SIGN_IN_USER_RESET_PASSWORD,
  UPDATE_ROLE,
  UPDATE_USER,
  VALIDATE_OTP,
  GET_TODAY_EMPLOYEES_ATTENDACE_LIST,
  BULK_ADD_EMPLOYEE_DETAIL,
  RESET_EMPLOYEE_ATTENDENCE,
  DEVICE_AUTH_REQUEST_STATUS_CHANGE,
  DEVICE_AUTH_REQUEST_LIST,
  GET_ALL_COMPANY_CONFIG,
  EDIT_COMPANY_CONFIG,
  GET_CONFIG,
  GET_EXPORT_EMPLOYEE_LIST,
  GET_FILTRED_EMPLOYEE_LIST,
  GET_COMPLIENCE_REPORT,
  CHECK_IN_ABCENT_EMPLOYEE_LIST,
  Add_EMPLOYEE_CHECKIN,
  CONTACT_US,
  PRESENT_ABSENT_LIST,
  PRESENT_ABSENT_COUNT,
  YEARLY_AVG,
  MONTHLY_AVG,
  DAILY_AVG,
  ACHIVERS_LIST,
  GET_LEAVE_REPORT,
  VISITORLIST,
  COMAPANY_DETAILS,
  UPDATE_COMAPANY_DETAILS,
  LEAVE_CATEGORY_TYPE,
  ACCRUALPERIOD,
  ACCRUAL_TIME,
  ADD_LEAVE_TYPE,
  LEAVE_LIST,
  UPDATE_LEAVE_TYPE,
  GET_ALL_LEAVE_CONFIG,
  GET_ALL_LEAVE_CYCLE_DATA,
  UPDATE_LEAVE_CONFIG,
  IMPORT_LEAVE_ADJUSTMENT_LIST,
  LEAVE_ADJUSTMENT_BULK_UPLOAD,
  EMPLOYEE_LEAVE_ADJUSTMENT_LIST,
  UPDATE_EMPLOYEE_LEAVE_ADJUSTMENT_LIST,
  LEAVE_TYPE,
  ACCRUAL_RESET,
  GET_EMPLOYEE_ACTIVITY_APP_LIST,
  CHANGE_APP_REVIEW_STATUS,
  GET_APP_VERSION_AND_PATH,
  ACTIVITY_EMPLOYEE_APP_DURATIONS,
  GET_DESKTOP_APP_VERSION_AND_PATH,
  GET_PRICING_PLANS,
  GET_COMPANY_PLAN,
  UPDATE_COMAPANY_PLAN_DETAILS,
  GET_VERIFY_EMAIL,
} from "app/Api/ApiEndPoint";
import { axiosApiInstance } from "app/Api/axios";
// import axios, { post } from 'axios';
import {
  CHEACK_IN_OUT_HISTORY,
  CHECK_IN,
  CHECK_OUT,
  EMPLOYEE_PROFILE,
  COMAPANY_LIST,
  COMPANYACTION,
  ADD_COMPANY_REQUEST_FORM,
  COMPANYREGISTER,
  GET_DASHBOARD_EMPLOYEE_STATUS,
  GET_EMPLOYEE_LEAVE_REPORT,
  EMPLOYEE_REQUEST_CHECKIN,
  GET_EMPLOYEE_REQUEST_LIST,
  GET_ADMIN_EMPLOYEE_REQUEST_LIST,
  EMPLOYEE_CHECKIN_OUT_CANCEL_REQUEST,
  HOLIDAY_CALENDER_LIST,
  DELETE_HOLIDAY,
  BULK_ADD_HOLIDAY,
  GET_PROJECT_LABEL,
  GET_PROJECT_TIMESHEET,
  GET_EDIT_REQUEST_CHECK_INOUT,
  UPDATE_EDIT_CHECKIN_OUT_REQUEST_DATA,
  GET_HOLIDAY_ROW_DATA,
  UPDATE_HOLIDAY_RECORD,
  UPDATE_LEAVE_REPORT_STATUS,
  GENERATE_LEAVE_MANUAL,
  GET_TODAY_ATTENDACE_REPORT,
  GET_EPLOYEE_LEAVE_LIST,
  ADMIN_CHECKIN_OUT_CANCEL_REQUEST,
  EMPLOYEE_MONTHLY_ACHIVERS,
  GET_EMPLOYEE_ACTIVITY_LIST,
  GET_EMPLOYEE_ACTIVITY_IMAGES_LIST,
  ACTIVITY_EMPLOYEE_LIST,
  EMPLOYEE_DEVICE_AUTH_REVOKE,
  APP_HEARTBEAT_EMPLOYEE_LIST,
  GET_USER_ACTIVITY_TRACKKER_CONFIG,
  PUT_USER_ACTIVITY_TRACKKER_CONFIG,
  // FORGOT_PASSWORD_URL,
  // LOGIN_URL,
  // RESET_PASSWORD,
  // VALIDATE_OTP,
} from "../Api/ApiEndPoint";
// import axios, { axiosMultipart } from "../Api/axios";

import axios, { axiosMultipart } from "../Api/axios";
import { useNavigate } from "react-router-dom";
import { nameConverter2 } from "app/utils/utils";
import moment from "moment";

export const login = async (userId, userPassword) => {
  const response = await axiosApiInstance.post(LOGIN_URL, {
    userId: userId,
    userPassword: userPassword,
  });
  return response;
};
export const employeeForgetPassword = async (userId) => {
  return await axiosApiInstance.put(FORGOT_PASSWORD_URL, {
    userId: userId,
  });
};
export const adminForgetPassword = async (adminId) => {
  return await axiosApiInstance.put(ADMIN_FORGOT_PASSWORD_URL, {
    adminId: adminId,
  });
};
export const adminValidateOTP = async (otp, forgetPasswordUserId) => {
  return await axiosApiInstance.put(ADMIN_VALIDATE_OTP, {
    otp: otp,
    adminId: forgetPasswordUserId,
  });
};
export const validateOTP = async (otp, forgetPasswordUserId) => {
  return await axiosApiInstance.put(VALIDATE_OTP, {
    otp: otp,
    userId: forgetPasswordUserId,
  });
};
export const resetPassword = async (authKey, newPass, conNewPass) => {
  return await axiosApiInstance.put(RESET_PASSWORD, {
    // userToken: token,
    userToken: authKey,
    password: newPass,
    comPassword: conNewPass,
  });
};
export const adminResetPassword = async (authKey, newPass, conNewPass) => {
  return await axiosApiInstance.put(ADMIN_RESET_PASSWORD, {
    // userToken: token,
    adminToken: authKey,
    password: newPass,
    comPassword: conNewPass,
  });
};

export const attendanceCheckIn = async (formData) => {
  return await axiosMultipart.post(CHECK_IN, formData);
};

export const attendanceCheckOut = async (formData) => {
  return await axiosMultipart.put(CHECK_OUT, formData);
};

export const GetEmployeeList = async (
  pageSize = 10,
  currentPage = 1,
  searchText
) => {
  return await axiosApiInstance.get(
    EMPLOYEE_LIST +
      `?limit=${pageSize}` +
      `&page=${currentPage + 1}` +
      `&employeeName=${searchText ? searchText : ""}`
  );
};

export const getVisitorList = async (params) => {
  return await axiosApiInstance.get(VISITORLIST, {
    params: {
      ...params,
    },
  });
};
export const getHolidayList = async (params) => {
  return await axiosApiInstance.get(HOLIDAY_CALENDER_LIST, {
    params: {
      ...params,
    },
  });
};

export const GetExportAllEmployeeList = async (
  pageSize = 10,
  currentPage = 1
) => {
  return await axiosApiInstance.get(GET_EXPORT_EMPLOYEE_LIST);
};

export const GetEmployeeDetail = async (id) => {
  return await axiosApiInstance.get(EMPLOYEE_DETAIL + id);
};

export const editEmployeeDetails = async (body, empId) => {
  return await axiosApiInstance.put(EDIT_EMPLOYEE_DETAIL + empId, {
    firstName: body?.firstName || "",
    officeEmail: body?.email || "",
    empGeneratedId: body?.empGeneratedId || "",
    workMode: body?.work_mode || "",
    isActive: body?.isActive,
    projectName: body?.projectName?.trim(),
    joiningDate: moment(body?.joiningDate).format("YYYY-MM-DD"),
  });
};

export const deleteEmployee = async (id) => {
  return await axiosApiInstance.put(DELETE_EMPLOYEE + id);
};
export const deleteCompanyHoliday = async (holidayId) => {
  return await axiosApiInstance.delete(
    DELETE_HOLIDAY + `?recordId=${holidayId}`
  );
};
export const getHolidayRowValue = async (holidayId) => {
  return await axiosApiInstance.get(
    GET_HOLIDAY_ROW_DATA + `?recordId=${holidayId}`
  );
};

export const importExcelEmployeeDetails = async (body, fileName) => {
  return await axiosApiInstance.post(
    BULK_ADD_EMPLOYEE_DETAIL + `?fileName=${fileName}`,
    body
  );
};

export const updateHoliday = async (body, holidayId) => {
  return await axiosApiInstance.put(
    UPDATE_HOLIDAY_RECORD + `?recordId=${holidayId}`,
    body
  );
};
export const importExcelHolidays = async (body, fileName) => {
  return await axiosApiInstance.post(BULK_ADD_HOLIDAY, body);
};
// export const importExcelHolidays = async (body) => {
//   return await axiosApiInstance.post(
//     BULK_ADD_HOLIDAY,
//     body
//   );
// };

export const addEmployeeDetails = async (body) => {
  return await axiosApiInstance.post(ADD_EMPLOYEE_DETAIL, {
    name: nameConverter2(body?.first_name),
    email: body?.office_email,
    employeeId: body?.employee_Id || "",
    workMode: body?.work_mode,
    projectName: body?.project_name?.trim(),
    joiningDate: moment(body?.joining_date).format("YYYY-MM-DD"),
  });
};

export const getAllRole = async () => {
  return await axiosApiInstance.get(GET_ALL_ROLES);
};
export const getAllDepartment = async () => {
  return await axiosApiInstance.get(GET_ALL_DEPARTMENT);
};
export const getAllDesignation = async () => {
  return await axiosApiInstance.get(GET_ALL_DESIGNATION);
};
export const getAllResources = async () => {
  return await axiosApiInstance.get(GET_ALL_RESOURCES);
};
export const getAllActions = async () => {
  return await axiosApiInstance.get(GET_ALL_ACTIONS);
};

export const getAllRoleList = async () => {
  return await axiosApiInstance.get(GET_ALL_ROLESLIST);
};

export const addNewRole = async (newObj, dummy) => {
  return await axiosApiInstance.post(ADD_NEW_ROLE, {
    roleName: dummy,
    privileges: newObj,
  });
};

export const deleteRole = async (id) => {
  return await axiosApiInstance.delete(DELETE_Role + id);
};
export const updateRole = async (inputList, id) => {
  return await axiosApiInstance.put(UPDATE_ROLE + id, ...inputList);
};
export const signInUserResetPassword = async (state) => {
  return await axiosApiInstance.put(SIGN_IN_USER_RESET_PASSWORD, {
    currentPassword: state?.oldPassword,
    password: state?.password,
    conformPassword: state?.comPassword,
  });
};

export const userUpdate = async (body) => {
  return await axiosApiInstance.put(UPDATE_USER, {
    ...body,
  });
};

export const getSingleEmployeeDetail = async () => {
  return await axiosApiInstance.get(GET_SINGLE_EMPLOYEE_DETAILS);
};

export const logOut = async () => {};
export const profile = async (id) => {
  let empProfile = id;
  return (empProfile = id
    ? await axios.get(EMPLOYEE_PROFILE + id)
    : "something went wrong");
};
export const editProfile = async () => {};

export const checkInOutHistory = async (params) => {
  return await axiosApiInstance.get(CHEACK_IN_OUT_HISTORY, {
    params: {
      ...params,
    },
  });
};
export const AdminCheckInOutHistory = async (params) => {
  return await axiosApiInstance.get(ADMIN_CHEACK_IN_OUT_HISTORY, {
    params: {
      ...params,
    },
  });
};
export const AdminTodayAttendaceList = async (params, todayDate) => {
  return await axiosApiInstance.get(
    GET_TODAY_EMPLOYEES_ATTENDACE_LIST + `/${todayDate}`,
    {
      params: {
        ...params,
      },
    }
  );
};

export const resetEmployeeAttendace = async (id, resetParam) => {
  return await axiosApiInstance.get(
    RESET_EMPLOYEE_ATTENDENCE + `?reset=${resetParam}`,
    {
      params: {
        attendanceId: `${id}`,
      },
    }
  );
};
export const geAbcenttEmployeeList = async (date) => {
  return await axiosApiInstance.get(CHECK_IN_ABCENT_EMPLOYEE_LIST + `/${date}`);
};
export const addEmployeeCheckIn = async (body) => {
  return await axiosApiInstance.post(Add_EMPLOYEE_CHECKIN, body);
};

export const employeeRequestChecking = async (body) => {
  return await axiosApiInstance.post(EMPLOYEE_REQUEST_CHECKIN, body);
};

export const GetRequestList = async (params) => {
  return await axiosApiInstance.get(DEVICE_AUTH_REQUEST_LIST, {
    params: {
      ...params,
    },
  });
};
export const acceptRequest = async (body) => {
  return await axiosApiInstance.put(DEVICE_AUTH_REQUEST_STATUS_CHANGE, {
    requestId: body?.requestId,
    isVerified: true,
  });
};
export const rejectRequest = async (body) => {
  return await axiosApiInstance.put(DEVICE_AUTH_REQUEST_STATUS_CHANGE, {
    requestId: body?.requestId,
    isVerified: false,
  });
};

export const getAllCompanyConfig = async () => {
  return await axiosApiInstance.get(GET_ALL_COMPANY_CONFIG);
};
export const editCompanyConfig = async (body) => {
  return await axiosApiInstance.put(EDIT_COMPANY_CONFIG, {
    configuration: body,
  });
};
export const getConfig = async () => {
  return await axiosApiInstance.get(GET_CONFIG);
};

export const searchReport = async (params) => {
  return await axiosApiInstance.get(GET_FILTRED_EMPLOYEE_LIST, {
    params: {
      ...params,
    },
  });
};

export const allemployeeData = async (exportReport) => {
  return await axiosApiInstance.get(GET_FILTRED_EMPLOYEE_LIST, {
    params: {
      export: exportReport,
    },
  });
};

export const GetComplienceReport = async (params) => {
  return await axiosApiInstance.get(GET_COMPLIENCE_REPORT, {
    params: {
      ...params,
    },
  });
};

export const getProjectLabel = async () => {
  return await axiosApiInstance.get(GET_PROJECT_LABEL, {});
};
export const getProjectTimeSheet = async (params) => {
  return await axiosApiInstance.get(GET_PROJECT_TIMESHEET, {
    params: {
      ...params,
    },
  });
};

export const contactUs = async (body) => {
  return await axiosApiInstance.post(CONTACT_US, {
    name: body?.name,
    email: body?.email,
    subject: body?.message,
    message: body?.description,
    phone: body?.phone_number,
  });
};

export const getAllCompanyList = async (params) => {
  return await axiosApiInstance.get(COMAPANY_LIST, {
    params: {
      ...params,
    },
  });
};
export const getCompanyDetails = async (id) => {
  return await axiosApiInstance.get(COMAPANY_DETAILS + id);
};
export const updateCompanyDetails = async (formData, id) => {
  return await axiosApiInstance.put(UPDATE_COMAPANY_DETAILS + id, formData);
};

export const companyAction = async (id, param) => {
  return await axiosApiInstance.put(
    COMPANYACTION + `/?companyId=${id}` + `&action=${param}`,
    {}
  );
};

export const requestCompanyForm = async (formData) => {
  return await axiosMultipart.post(ADD_COMPANY_REQUEST_FORM, formData);
};

export const comRegister = async (formData) => {
  return await axiosMultipart.post(COMPANYREGISTER, formData);
};

export const getDashboardEmployeeStatus = async (id) => {
  return await axiosApiInstance.get(GET_DASHBOARD_EMPLOYEE_STATUS);
};
export const PresentAbsentList = async () => {
  return await axiosApiInstance.get(PRESENT_ABSENT_LIST);
};
export const PresentAbsentCount = async () => {
  return await axiosApiInstance.get(PRESENT_ABSENT_COUNT);
};
export const YearlyAvg = async () => {
  return await axiosApiInstance.get(YEARLY_AVG);
};
export const MonthlyAvg = async () => {
  return await axiosApiInstance.get(MONTHLY_AVG);
};
export const DailyAvg = async () => {
  return await axiosApiInstance.get(DAILY_AVG);
};
export const getUserTodayAttendaceReport = async () => {
  return await axiosApiInstance.get(GET_TODAY_ATTENDACE_REPORT);
};
export const AchiversList = async (params) => {
  return await axiosApiInstance.get(ACHIVERS_LIST, {
    params: {
      ...params,
    },
  });
};

export const GetLeaveReport = async (params) => {
  return await axiosApiInstance.get(GET_LEAVE_REPORT, {
    params: {
      ...params,
    },
  });
};

export const getEmployeeLeaveReport = async (params) => {
  return await axiosApiInstance.get(GET_EMPLOYEE_LEAVE_REPORT, {
    params: {
      ...params,
    },
  });
};
export const getCheckinRequests = async (params) => {
  return await axiosApiInstance.get(GET_EMPLOYEE_REQUEST_LIST, {
    params: {
      ...params,
    },
  });
};
export const getEmpLeaveList = async (params) => {
  return await axiosApiInstance.get(GET_EPLOYEE_LEAVE_LIST, {
    params: {
      ...params,
    },
  });
};
export const getAdminCheckinRequests = async (params) => {
  return await axiosApiInstance.get(GET_ADMIN_EMPLOYEE_REQUEST_LIST, {
    params: {
      ...params,
    },
  });
};

export const getEditReuestCheckInout = async (requestId) => {
  return await axiosApiInstance.get(
    GET_EDIT_REQUEST_CHECK_INOUT + `?attendanceRequestId=${requestId}`
  );
};
export const updateCheckOutRequest = async (formData) => {
  return await axiosMultipart.put(
    UPDATE_EDIT_CHECKIN_OUT_REQUEST_DATA,
    formData
  );
};
export const updateLeaveReport = async (leaveIds) => {
  return await axiosApiInstance.put(UPDATE_LEAVE_REPORT_STATUS, leaveIds);
};

export const generateLeave = async (formData) => {
  return await axiosMultipart.post(GENERATE_LEAVE_MANUAL, formData);
};

export const cancelCheckinRequest = async (requestId) => {
  return await axiosApiInstance.put(
    EMPLOYEE_CHECKIN_OUT_CANCEL_REQUEST + `?attendanceRequestId=${requestId}`
  );
};
export const AdminCancelCheckinRequest = async (body) => {
  return await axiosApiInstance.put(ADMIN_CHECKIN_OUT_CANCEL_REQUEST, body);
};
// Leave management System

export const getLeaveCategoryType = async () => {
  return await axiosApiInstance.get(LEAVE_CATEGORY_TYPE);
};
export const getAccrualPeriod = async () => {
  return await axiosApiInstance.get(ACCRUALPERIOD);
};
export const getAccrualTime = async () => {
  return await axiosApiInstance.get(ACCRUAL_TIME);
};
export const getAccrualReset = async () => {
  return await axiosApiInstance.get(ACCRUAL_RESET);
};
export const getAllLeaveType = async () => {
  return await axiosApiInstance.get(LEAVE_TYPE);
};

export const addLeaveType = async (body) => {
  return await axiosApiInstance.post(ADD_LEAVE_TYPE, { ...body });
};

export const getLeaveTypeList = async (params) => {
  return await axiosApiInstance.get(LEAVE_LIST, {
    params: {
      ...params,
    },
  });
};
export const updateLeaveType = async (body) => {
  return await axiosApiInstance.put(UPDATE_LEAVE_TYPE, { ...body });
};

export const getAllLeaveConfig = async () => {
  return await axiosApiInstance.get(GET_ALL_LEAVE_CONFIG);
};
export const getAllLeaveCycleDate = async () => {
  return await axiosApiInstance.get(GET_ALL_LEAVE_CYCLE_DATA);
};
export const updateLeaveConfig = async (body) => {
  return await axiosApiInstance.put(UPDATE_LEAVE_CONFIG, { ...body });
};

export const importLeaveAdjustmentList = async (params) => {
  return await axiosApiInstance.get(IMPORT_LEAVE_ADJUSTMENT_LIST, {
    params: {
      ...params,
    },
  });
};
export const leaveAdjustmentBulkUpload = async (body) => {
  return await axiosApiInstance.post(LEAVE_ADJUSTMENT_BULK_UPLOAD, body);
};

export const getAllEmployeeAdjustedLeaves = async (params) => {
  return await axiosApiInstance.get(EMPLOYEE_LEAVE_ADJUSTMENT_LIST, {
    params: {
      ...params,
    },
  });
};
export const updateEmployeeAdjustedLeaves = async (body) => {
  return await axiosApiInstance.put(UPDATE_EMPLOYEE_LEAVE_ADJUSTMENT_LIST, {
    ...body,
  });
};

export const employeeAchiversMostTimeSpend = async (params) => {
  return await axiosApiInstance.get(EMPLOYEE_MONTHLY_ACHIVERS);
};

export const getEmployeeActivityData = async (params) => {
  return await axiosApiInstance.get(GET_EMPLOYEE_ACTIVITY_LIST, {
    params: {
      ...params,
    },
  });
};
export const getEmployeeActivityDurationsData = async (params) => {
  return await axiosApiInstance.get(ACTIVITY_EMPLOYEE_APP_DURATIONS, {
    params: {
      ...params,
    },
  });
};

export const getEmployeeActivityImagesList = async (params) => {
  return await axiosApiInstance.get(GET_EMPLOYEE_ACTIVITY_IMAGES_LIST, {
    params: {
      ...params,
    },
  });
};
export const getAppReviewList = async (params) => {
  return await axiosApiInstance.get(GET_EMPLOYEE_ACTIVITY_APP_LIST, {
    params: {
      ...params,
    },
  });
};

export const changeAppActivitySatus = async (params) => {
  return await axiosApiInstance.put(CHANGE_APP_REVIEW_STATUS, params);
};

// export const activityEmployeeList = async (params) => {
//   return await axiosApiInstance.get(ACTIVITY_EMPLOYEE_LIST, params);
// };

export const activityEmployeeList = async (param) => {
  return await axiosApiInstance.get(
    ACTIVITY_EMPLOYEE_LIST + `/${param?.activity}` + `?date=${param?.date}`,
    {}
  );
};
export const deviceAuthRevoke = async (params) => {
  return await axiosApiInstance.put(EMPLOYEE_DEVICE_AUTH_REVOKE, params);
};

export const getHearBeatEmployeeList = async (params) => {
  return await axiosApiInstance.get(APP_HEARTBEAT_EMPLOYEE_LIST, {
    params: {
      ...params,
    },
  });
};

export const getuserActivityConfig = async () => {
  return await axiosApiInstance.get(GET_USER_ACTIVITY_TRACKKER_CONFIG);
};
export const updateUserActivityConfig = async (params) => {
  return await axiosApiInstance.put(PUT_USER_ACTIVITY_TRACKKER_CONFIG, params);
};


export const getMobileAppVersion = async () => {
  return await axiosApiInstance.get(GET_APP_VERSION_AND_PATH);
};
export const getDesktioAppVersion = async () => {
  return await axiosApiInstance.get(GET_DESKTOP_APP_VERSION_AND_PATH);
};

export const getPricingPlan = async () => {
  return await axiosApiInstance.get(GET_PRICING_PLANS);
};

export const getCompanyPlanDetails = async () => {
  return await axiosApiInstance.get(GET_COMPANY_PLAN);
};

export const updateExsitCompanyPlan = async (params) => {
  return await axiosApiInstance.put(UPDATE_COMAPANY_PLAN_DETAILS, params);
};

export const getVerifyEmail = async (token) => {
  return await axiosApiInstance.get(`${GET_VERIFY_EMAIL}?token=${token}`);
};

